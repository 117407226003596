import React, {Component} from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import LandingRoutes from './common/LandingRoutes';
import BaseLayout from './common/BaseLayout';
import IdleTimer from 'react-idle-timer';
import './App.css';

@inject('userStore')
@withRouter
@observer
class App extends Component {
  constructor(props) {
   super(props)
   this.idleTimer = null
  }

  handleOnIdle = (event) => {
    this.props.userStore.logout();
  }

  render(){
    if (this.props.userStore.token !== '') {
      return (
        <>
          <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              timeout={1000 * 60 * 60}
              onIdle={this.handleOnIdle}
              debounce={250}
            />
          <BaseLayout/>
        </>
      );
    } else {
      return (
        <LandingRoutes/>
      )
    }
  }
}
export default App;
