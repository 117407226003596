import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import { connectionPost} from '../utils/connection';
import { auth } from '../firebase';
import 'react-toastify/dist/ReactToastify.css';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
@observer
class Login extends Component{
  constructor(props){
    super(props);
    this.state = {
      login: {email: '',password: ''},
      error: null,
      forgottenPassword:false
    };
  }

  onSubmit = (event) => {
  	event.preventDefault();
  	const {login, forgottenPassword} = this.state;
  	const { history,} = this.props;
    if (!forgottenPassword){
      auth.doSignInWithEmailAndPassword(login.email, login.password)
        .then((res) => {
          this.props.userStore.get_token(res.user.uid);
        })
        .catch(error => {
          toast.error("Incorrect Username/Password", toastParams);
          this.setState(byPropKey('error', error));
      });
    } else {
      // reset password
      auth.doSendPasswordResetEmail(login.email)
        .then((res) => {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
        })
        .catch(error => {
          toast.error(error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
          this.setState(byPropKey('error', error));
      });
    }
	}

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.login);
    let value = e.target.value;
    updated_obj[e.target.name] = value;
    this.setState({login:updated_obj});
  }

  render(){
    const {login, error, forgottenPassword} = this.state;
    const isInvalid = login.email === '';
    document.getElementById('root').classList.add('full-height');
    return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />

      <div className="login-wrapper ">
        <div className="bg-pic">
          <div className="bg-caption text-white p-l-20 m-b-20">
            <h1 className="semi-bold text-white m-b-100">RE-VU.me</h1>
          </div>
        </div>
        <div className="login-container bg-white">
          <div className="p-l-50 p-r-50 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
            <img src={require('../img/logo.revu.sm.png')} alt="logo" data-src={require('../img/logo.revu.sm.png')} width="130"/>
            <h2 className="p-t-25">Welcome Back</h2>
            <p className="mw-80 m-t-5">{forgottenPassword ? 'Request a password reset' : 'Please sign in to your account'}</p>
            { error && <p>{error.message}</p> }
            <form id="form-login" className="p-t-15" role="form" onSubmit={this.onSubmit} noValidate="novalidate">
              <div className="form-group form-group-default">
                <label>Email</label>
                <div className="controls">
                  <input type="text" name="email" className="form-control" required="" aria-required="true" value={login.email} onChange={this.handleInputChange}/>
                  {forgottenPassword ? <span className="help">A new password will be sent to this email address.</span> : null}
                </div>
              </div>
              {!forgottenPassword ?
              <React.Fragment>
                <div className="form-group form-group-default">
                  <label>Password</label>
                  <div className="controls">
                    <input type="password" className="form-control" name="password" required="" aria-required="true" value={login.password} onChange={this.handleInputChange}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 offset-md-6 d-flex align-items-center justify-content-end">
                    <button aria-label="" className={isInvalid ? "btn btn-primary btn-lg m-t-10 disabled" : "btn btn-primary btn-lg m-t-10"} type="submit">Sign in</button>
                  </div>
                </div>
                <div className="m-b-5 m-t-30">
                  <a href="#" onClick={()=>this.setState({forgottenPassword:!this.state.forgottenPassword})} className="normal">Forgotten your password?</a>
                </div>
              </React.Fragment>
              :
              <React.Fragment>
                <div className="row">
                  <div className="col-md-6 offset-md-6 d-flex align-items-center justify-content-end">
                    <button aria-label="" className="btn btn-primary btn-lg m-t-10" type="submit">Request Password Reset</button>
                  </div>
                </div>
                <div className="m-b-5 m-t-30">
                  <a href="#" onClick={()=>this.setState({forgottenPassword:!this.state.forgottenPassword})} className="normal">Remember your Password?  Sign In.</a>
                </div>
              </React.Fragment>
              }
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
    )
  }
}
export default Login;
// <div>
//   <a href="/register" className="normal">No account? Register.</a>
// </div>
