import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
	apiKey: "AIzaSyA8efqflyNI06snTpUKU1sgjGZV55T1WBI",
	authDomain: "omgyes-74ecc.firebaseapp.com",
	databaseURL: "https://omgyes-74ecc.firebaseio.com",
	projectId: "omgyes-74ecc",
	storageBucket: "omgyes-74ecc.appspot.com",
	messagingSenderId: "293239827110"
};

if (!firebase.apps.length) {
	firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
  auth,
};
