import React, { Component } from 'react';
import { connectionGet } from '../utils/connection';
import { inject } from 'mobx-react';
import moment from 'moment';
import WordCloud from 'react-d3-cloud';

const fontSizeMapper = word => word.value * 20;
const rotate = word => word.value;


@inject('userStore')
class ProfileAnalysisWords extends Component {
  constructor(props){
    super(props);
    this.state = {
      analysis:null,
      loading:false,
    }
  }

  componentDidMount(){
    this.selectAnalysis(this.props.analysis_hash_id, this.props.profile.hash_id);
  }

  componentWillReceiveProps(nextProps){
    this.selectAnalysis(nextProps.analysis_hash_id, nextProps.profile.hash_id);
  }

  selectAnalysis = (analysis_hash_id, profile_hash_id) => {
    // fetch the analysis by hash_id
    if (analysis_hash_id){
      this.setState({loading:true},()=>{
        connectionGet('profiles/' + profile_hash_id + '/analysis/' + analysis_hash_id, {}, {username: this.props.userStore.token}
        ).then((res) => {
          if (res.data.errors === 0){
            this.setState({
              analysis:res.data.profile_analysis,
              loading:false
            });
          } else {
            this.setState({
              loading:false
            });
            alert(res.data.message);
          }
        }).catch((error) => {
          console.log(error);
          return error;
        });
      });
    }
  }

  render(){
    const {profile, analysis_list} = this.props;
    const {analysis, loading} = this.state;
    return (
      <React.Fragment>
      {analysis && analysis.sentiment_json ?
        Object.keys(analysis.sentiment_json.locations).map((key, index)=>
        <React.Fragment key={index}>
          <div className="card card-bordered">
            <header className="card-header">
              <h4 className="card-title">{profile.thing_dict[key]}</h4>
            </header>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h3>Positive</h3>
                  <WordCloud
                      data={analysis.sentiment_json.locations[key].positive_words}
                      fontSizeMapper={fontSizeMapper}
                      rotate={rotate}
                  />
                </div>
                <div className="col-12">
                  <h3>Negative</h3>
                  <WordCloud
                      data={analysis.sentiment_json.locations[key].negative_words}
                      fontSizeMapper={fontSizeMapper}
                      rotate={rotate}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        )
      : <div className="alert alert-info">Select an Analysis record to see results</div>}
      </React.Fragment>
    );
  }
}

export default ProfileAnalysisWords;
