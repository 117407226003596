import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SketchPicker } from 'react-color';

@inject('userStore')
class ThingDetailModal extends Component{
    constructor(props){
        super(props);
    }

    handleChangeComplete = (color) => {
       // this.setState({ background: color.hex },()=>{
       //     this.formApi.setValue('graph_color', color.hex);
       //     console.log(this.formApi.getState().values);
       // });
    };

    render(){
        if (!this.props.thing) return <div className="alert alert-info">Loading Thing Details...</div>;
        const {thing, handleInputChange, question_set_list, incentive_list, account_list} = this.props;
        const isInvalid = thing.name === '';
        return (
        <React.Fragment>
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="m-t-0"><i className="pg-icon">alert</i> Thing Details - {thing.id !== 0 ? thing.name : 'Add'}</h3>
            </div>
            <div className="modal-body">
              <form role="form" id="thing-detail">
                <div className="form-group-attached">
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group form-group-default">
                          <label>Name</label>
                          <input className="form-control" name="name" type="text" onChange={handleInputChange} value={thing.name}/>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group form-group-default">
                          <label>Vanity</label>
                          <input className="form-control" name="unique_name" type="text" onChange={handleInputChange} value={thing.unique_name}/>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group form-group-default">
                          <label>Account Owner</label>
                          <select className="form-control" name="owner_account_id" onChange={handleInputChange} value={thing.owner_account_id}>
                          {account_list.map((account, a_index)=>(
                            <option value={account.id} key={a_index}>{account.account_name}</option>
                          ))}
                          </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="form-group form-group-default">
                        <label className="">Thing Type</label>
                        <select className="form-control" name="sys_thing_type_id" onChange={handleInputChange} value={thing.sys_thing_type_id}>
                          <option value="1">Location</option>
                          <option value="2">Object</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group form-group-default">
                        <label className="">Days Between</label>
                        <input className="form-control" name="days_between" type="text" onChange={handleInputChange} value={thing.days_between}/>
                      </div>
                    </div>
                  </div>
                  {thing.question_set_list ?
                  <div className="row">
                      <div className="form-group form-group-default col-6">
                        <label className="">Existing Question Set</label>
                        <select className="form-control" name="question_set_id" onChange={handleInputChange} value={thing.question_set_id}>
                        {question_set_list.map((question, q_index)=>(
                          <option value={question.id} key={q_index}>{question.name}</option>
                        ))}
                        </select>
                      </div>
                      <div className="form-group form-group-default col-6">
                        <label className="">Chart Color</label>
                        <SketchPicker color={thing.chart_color} onChangeComplete={ this.handleChangeComplete }/>
                        <input className="form-control" name="chart_color" value={thing.chart_color} onChange={handleInputChange} type="hidden"/>
                      </div>
                  </div>
                  : null}
                </div>
              </form>
              <div className="row m-t-20">
                <div className="col-md-6 offset-md-6">
                  <div className="btn-group pull-right">
                    <button  type="button" className={isInvalid ? "btn btn-complete disabled" : "btn btn-complete"} onClick={isInvalid ? ()=>{return false;} : this.props.submit}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </React.Fragment>
        );
    }
}

export default ThingDetailModal;
