import React, { Component } from 'react';
import FormattedReviewText from './FormattedReviewText';

class QRBlock extends Component {
  constructor(props){
    super(props);
  }

  render(){
    const {topic, stars} = this.props;
    return (
      <>
        <dd className="col-sm-3 mb-0 semi-bold">{topic}</dd>
        <dt className="col-sm-9">{
          Array(stars).fill().map((_, index) =>
            <small key={index} className="fs-30" style={{color:'#FFd945'}}><i className="pg-icon">star</i></small>
          )
        }</dt>
      </>
    )
  }
}


class ReviewBlock extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  render(){
    const { review } = this.props;
    return (
      <div className="card social-card share" style={{width:'100%'}}>
        <div className="card-header clearfix" onClick={review.url && review.url !== '' ? ()=>window.open(review.url, "_blank") : null}>
          <div className="user-pic">
          {review.profile_picture ?
            <img alt="pro" width="33" height="33" data-src-retina={review.profile_picture} data-src={review.profile_picture} src={review.profile_picture}/>
          :
            <img alt="pro" width="33" height="33" data-src-retina={require('../img/ttd.png')} data-src={require('../img/ttd.png')} src={require('../img/ttd.png')}/>
          }
          </div>
          <h5>{review.name}</h5>
          <h6 className="d-flex align-items-center">{review.review_date}</h6>
        </div>
        <div className="card-description">
        {review.sys_review_source === 'RE-VU.me' ?
          review.analyzed.analysis[0].sentence !== '' ? <p className="aspect-p">{review.analyzed.analysis[0].sentence}</p> : null
        : null}
        {review.sys_review_source === 'RE-VU.me' ?
        <dl className="row">{
          review.analyzed.analysis[0].topic_classified.map((topic, index)=>{
            if (topic[1] === 1){
              let i = 5;
              return <QRBlock stars={i} topic={topic[0]}/>
            } else if (topic[1] === 0.75) {
              let i = 4;
              return <QRBlock stars={i} topic={topic[0]}/>
            } else if (topic[1] === 0.5) {
              let i = 3;
              return <QRBlock stars={i} topic={topic[0]}/>
            } else if (topic[1] === 0.25) {
              let i = 2;
              return <QRBlock stars={i} topic={topic[0]}/>
            } else if (topic[1] === 0) {
              let i = 1;
              return <QRBlock stars={i} topic={topic[0]}/>
            }
          })}
        </dl>
        : null}
        {review.analyzed && Array.isArray(review.analyzed.analysis) ?
          review.sys_review_source !== 'RE-VU.me' ?
          <React.Fragment>
            <FormattedReviewText review={review}/>
          </React.Fragment>
          : null
        : <p className="small">No Review was left</p>}
          <div className="via">via {review.sys_review_source}</div>
        </div>
      </div>
    )
  }
}

export default ReviewBlock;
