import React, { Component } from 'react';

class Score extends Component {
  render() {
    const {score, size, asLetter, isConverted, fontSize, count, scoreType, scoreComp, insights} = this.props;
    let fs = 'fs-10';
    if (fontSize){
      fs = fontSize;
    }
    let percent = 0;
    if (score && !isConverted){
      percent = (score + 1) * 50;
      percent = parseInt(percent);
    } else {
      percent = parseInt(score);
    }
    let derivation = null;
    let direction = null;
    if (scoreComp && score){
      let diff = score - scoreComp;
      if (diff > 0){ // positive difference
        direction = <i className="pg-icon text-success">drop_up</i>;
      } else {
        direction = <i className="pg-icon text-danger">drop_down</i>;
      }
      derivation = ((Math.abs(diff)/scoreComp) * 100).toFixed(2);
    }
    let grade = '';
    let color = '';
    if (asLetter && count > 0){
      switch (true) {
        case percent < 50:
          grade = 'F';
          color = '#F35B04';
          break;
        case percent < 60:
          grade = 'D';
          color = '#F18701';
          break;
        case percent < 70:
          grade = 'C';
          color = '#fdd501';
          break;
        case percent < 80:
          grade = 'B';
          color = '#78CFA9';
          break;
        case percent < 90:
          grade = 'A';
          color = '#46be8a';
          break;
        case percent <= 100:
          grade = 'A+';
          color = '#3A9C71';
          break;
        default:
      }
    } else {
      if (score){
        grade = percent + '%';
        switch (true) {
          case percent < 50:
            color = '#F35B04';
            break;
          case percent < 60:
            color = '#F18701';
            break;
          case percent < 70:
            color = '#fdd501';
            break;
          case percent < 80:
            color = '#78CFA9';
            break;
          case percent < 90:
            color = '#46be8a';
            break;
          case percent <= 100:
            color = '#3A9C71';
            break;
          default:
        }
      }
    }
    if (scoreType){
      if (count && count > 0){
        return (
          <React.Fragment>
            <span className="fs-16" style={{fontWeight:'bolder', color:color}}>{grade}</span><br/>
            {derivation ? <span className="fs-10 d-block">{direction}{derivation}%</span> : null}
            {!insights ? <span className="fs-10 text-hint">({count} Insights)</span> : null}
          </React.Fragment>
        );
      } else {
        return null;
      }
      return (
        <React.Fragment>
          <div className="card card-bordered mb-0">
            <div className="card-body p-10 text-center">
              <span className="fs-30" style={{fontWeight:'bolder', color:color}}>{grade}</span><br/>
              {derivation ? <span>{direction}{derivation}%</span> : null}
            </div>
            <footer className="card-footer p-0 text-center">
              <span className="mr-10 fs-12">{percent}%</span><span className="fs-10 text-hint">({count} Insights)</span>
            </footer>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div title={percent} data-provide="easypie" data-percent={percent} data-size={size} data-color={color} data-scale-color="#fff" data-line-width={4}>
          <span className={"easypie-data " + fs} style={{fontFamily:'Aleo, serif'}}>{grade}</span>
          <canvas height={size} width="0"></canvas>
        </div>
      );
    }
  }
}

export default Score;
