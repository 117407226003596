import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { connectionGet, connectionPost } from '../utils/connection';
import AccountsNsa from './AccountsNsa';

@inject('userStore')
class Admin2 extends Component {
    constructor(props){
        super(props);
        this.state = {
          selectedComponent:null,
          componentName:'Accounts'
        }
    }

    componentDidMount() {
      this.setState({
        selectedComponent: <AccountsNsa/>
      })
    }

    selectComponent = (componentName) => {
      switch (componentName) {
        case 'Accounts':
          this.setState({
            selectedComponent: <AccountsNsa/>,
            componentName:componentName
          })
          break;
        default:

      }
    }


    render() {
      const {selectedComponent, componentName} = this.state;
      return (
      <React.Fragment>
      <div className="no-padding container-fixed-lg bg-white" style={{minHeight:"600px"}}>
        <div className="container">
          <div className="card card-transparent">
            <div className="card-header p-l-0">
              <div className="card-title">
                <h4>Administration</h4>
              </div>
            </div>
            <div className="card-body p-l-0 p-r-0">
              <div className="row">
                <div className="col-lg-3 col-sm-12 b-r b-grey">
                  <div className="btn-group-vertical d-block">
                    <button className="btn btn-outline-default" onClick={()=>this.selectComponent('Accounts')}>Accounts</button>
                  </div>
                </div>
                <div className="col-lg-9 col-sm-12">
                  {selectedComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
      );
    }
}

export default Admin2;
