import React, { Component } from 'react';
import { inject } from 'mobx-react';

@inject('userStore')
class AccountForm extends Component{
    constructor(props){
        super(props);
    }
    render(){
        if (!this.props.account) return <div className="alert alert-info">Loading Account Details...</div>;
        const {account, handleInputChange} = this.props;
        return (
        <React.Fragment>
        <div className="form-type-combine">
          <div className="form-groups-attached">
              <div className="row">
                  <div className="form-group col-4">
                      <label>First Name</label>
                      <input className="form-control" name="first_name" type="text" onChange={handleInputChange} value={account.first_name}/>
                  </div>
                  <div className="form-group col-4">
                      <label>Last Name</label>
                      <input className="form-control" name="last_name" type="text" onChange={handleInputChange} value={account.last_name}/>
                  </div>
                  <div className="form-group col-4">
                      <label>Email</label>
                      {account.id !== 0 ?
                        <p className="form-control-plaintext">{account.email}</p>
                        :
                        <input className="form-control" name="email" type="text" onChange={handleInputChange} value={account.email}/>
                      }
                  </div>
              </div>
              <div className="row">
                  <div className="form-group col-4">
                      <label>Password</label>
                      {account.id !== 0 ?
                        <p className="form-control-plaintext">reset by account only</p>
                        :
                        <input className="form-control" name="password" type="password" onChange={handleInputChange} value={account.password}/>
                      }
                  </div>
                  <div className="form-group col-4">
                    <label className="">Status</label>
                    <select className="form-control" name="sys_status_id" onChange={handleInputChange} value={account.sys_status_id}>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </div>
                  {this.props.userStore.id === 1 ?
                  <div className="form-group col-4">
                    <label className="">Admin?</label>
                    <select className="form-control" name="is_admin" onChange={handleInputChange} value={account.is_admin}>
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </select>
                  </div>
                  : null}
                  <div className="form-group col-4">
                    <label className="">Prism?</label>
                    <select className="form-control" name="is_prism" onChange={handleInputChange} value={account.is_prism}>
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </select>
                  </div>
              </div>
          </div>
        </div>
        </React.Fragment>
        );
    }
}

export default AccountForm;
