import React, { Component } from 'react';
import {inject} from 'mobx-react';
import { connectionGetBlob, connectionGet, connectionPost } from '../utils/connection';
import DatePicker from 'react-datepicker'
import {Spinner} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import AnalysisReviewsQuickview from './AnalysisReviewsQuickview';
import AnalysisDetail from './AnalysisDetail';
import Modal from 'react-bootstrap4-modal';

class Filter{
  date_range = [7, 'Last Week'];
  start_date = new Date();
  end_date = new Date();
  location_list = [];
  aspect_custom_id = 0;
}

@inject('userStore')
class ProfileDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      profile:null,
      analysis_list:[],
      selected_analysis:null,
      start_date:new Date(),
      end_date:new Date(),
      filter:new Filter(),
      quickviewVisible:false,
      qvTitle:'',
      analysis_loading:false,
      review_list:[],
      selected_date:[7, 'Last Week'],
      date_list:[[1,'Today'], [7,'Last Week'],[30,'Last 30 Days'],[90,'Last 90 Days'],[0,'Custom Range']],
      pdf:null
    }
  }
  // date_list:[[1,'Today'], [7,'Last Week'],[30,'Last 30 Days'],[90,'Last 90 Days'],[365,'Last Year'], [0,'Custom Range']],

  componentDidMount(){
    this.fetchProfile();
  }

  setStart = (date) => {
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.end_date = moment(this.state.end_date).utc().format('MM/DD/YYYY');
    updated_filter.start_date = moment(date).utc().format('MM/DD/YYYY');
    updated_filter.date_range = [0, 'Custom Range'];
    this.setState({
      selected_date: updated_filter.date_range,
      start_date: date,
      filter:updated_filter
    });
  }

  setEnd = (date) => {
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.start_date = moment(this.state.start_date).utc().format('MM/DD/YYYY');
    updated_filter.end_date = moment(date).utc().format('MM/DD/YYYY');
    updated_filter.date_range = [0, 'Custom Range'];
    this.setState({
      selected_date: updated_filter.date_range,
      end_date: date,
      filter:updated_filter
    });
  }

  setDateRange = (e) => {
    let updated_filter = Object.assign({}, this.state.filter);
    let range = this.state.date_list.find(f => f[0] === parseInt(e.target.value));
    updated_filter.date_range = range
    // if (range[0] === 0){
    //   // set the start and end
    //   updated_filter.end_date = moment(this.state.end_date).utc().format('MM/DD/YYYY');
    //   updated_filter.start_date = moment(this.state.start_date).utc().format('MM/DD/YYYY');
    // }
    this.setState({
      selected_date:range,
      filter:updated_filter
    })
  }

  analyze = () => {
    return false;
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:false,
      review_list:[],
      qvTitle:''
    });
  }

  getResults = (loc_key, location, aspect) => {
    this.setState({
      quickviewVisible:true,
      loading:true
    }, ()=>{
      connectionPost('profiles/' + this.state.profile.hash_id + '/aspect/' + this.state.selected_analysis.hash_id, {location_id:loc_key, aspect:aspect}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          this.setState({
            review_list:res.data.results,
            loading:false,
            qvTitle:location + ": " + aspect
          });
        }

      }).catch((error) => {
        this.setState({
          loading:false
        })
        return error;
      });
    });
  }

  getAnalysisResults = (filter) => {
    this.setState({
      quickviewVisible:true,
      loading:true
    },()=>{
      connectionPost('profiles/reviews', {filter:filter}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          this.setState({
            review_list:res.data.results,
            quickviewVisible:true,
            loading:false
          })
        }
      }).catch((error) => {
        return error;
      });
    })
  }

  loadReviews = (loc_id, analysis) => {
    let filter = new Filter();
    filter.aspect_custom_id = this.state.profile.aspect_custom_id;
    filter.date_range = [0, 'Custom Range'];
    filter.end_date = moment(analysis.end_date).utc().format('MM/DD/YYYY');
    filter.start_date = moment(analysis.start_date).utc().format('MM/DD/YYYY');
    filter.location_list.push(loc_id);
    this.getAnalysisResults(filter);
  }

  fetchProfile = () => {
    connectionGet('v2/profiles/' + this.props.match.params.hashId, {}, { username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        this.setState({
          profile:res.data.profile,
          analysis_list:res.data.analysis_list
        },()=>{
          if (res.data.profile.last_analysis){
            this.fetchAnalysis(res.data.profile.last_analysis.hash_id);
            this.generatePDF(res.data.profile.last_analysis.hash_id)
          }
        });
      }
    }).catch((error) => {
      return error;
    });
  }

  handleAnalysisSelect = (e) => {
    this.fetchAnalysis(e.target.value);
    this.generatePDF(e.target.value);
  }

  runAnalysis = () => {
  // runAnalysis = (start_date, end_date) => {
    this.setState({
      analysisLoading:true
    },()=>{
      connectionPost('profiles/' + this.props.match.params.hashId + '/analysis', {filter:this.state.filter}, {username: this.props.userStore.token}
      ).then((res) => {
        let message = res.data.message;
        this.setState({
          analysisLoading:false,
          selected_analysis:res.data.profile_analysis
        },()=>this.generatePDF(res.data.profile_analysis.hash_id));
      }).catch((error) => {
        this.setState({
          analysisLoading:false
        });
        return error;
      });
    });
  }

  fetchAnalysis = (analysis_hash_id) => {
    console.log(analysis_hash_id)
    connectionGet('profiles/' + this.state.profile.hash_id + '/analysis/' + analysis_hash_id, {}, { username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        this.setState({
          selected_analysis:res.data.profile_analysis
        });
      }
    }).catch((error) => {
      return error;
    });
  }

  generatePDF = (analysis_hash_id) => {
    connectionGetBlob('analysis/pdf/' + analysis_hash_id, {}, {username: this.props.userStore.token})
    .then((res)=>{
      this.setState({
        pdf:{data:res.data}
      })
      console.log(res.data);
    })
    .catch((error)=>console.log(error));
  }

  render(){
    if (!this.state.profile) return <div className="no-padding container-fixed-lg bg-white"><div className="container"><div className="card card-transparent"><h3><Spinner/> Loading...</h3></div></div></div>;
    const {profile, date_list, start_date, end_date, filter, analysis_list, selected_analysis, analysisLoading, quickviewVisible, qvTitle, review_list, pdf, loading} = this.state;
    return (
      <React.Fragment>
      <Modal visible={quickviewVisible} className="modal slide-right" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.closeHandler}>
        <AnalysisReviewsQuickview backgroundClickHandler={this.closeHandler} loading={loading} qvTitle={qvTitle} review_list={review_list} />
      </Modal>
      <div className="no-padding container-fixed-lg bg-white" style={{minHeight:"600px"}}>
        <div className="container">
          <div className="card card-transparent">
            <div className="card-header p-l-0">
              <div className="card-title">
                <h4>{profile ? profile.name : null} Analysis</h4>
              </div>
            </div>
            <div className="card-body p-l-0 p-r-0">
              <p className="text-hint">Select a Generated Analysis or Generate one manually</p>
              <div className="row">
                <div className="col-lg-3 col-sm-12 b-r b-grey">
                  <form role="form">
                    <div className="">
                      <div className="row b-b b-grey">
                        <div className="form-group form-group-default">
                          <label>Last 10 Analysis Records:</label>
                          <select className="form-control" onChange={this.handleAnalysisSelect}>
                          {analysis_list.map((analysis, analysis_index) =>
                            <option key={analysis_index} value={analysis.hash_id}>{analysis.name}</option>
                          )}
                          </select>
                        </div>
                      </div>
                      <div className="row m-t-10">
                        <div className="form-group form-group-default">
                          <label>Date Range:</label>
                          <select className="form-control" onChange={this.setDateRange}>
                          {date_list.map((range, index) =>
                            <option key={index} value={range[0]}>{range[1]}</option>
                          )}
                          </select>
                        </div>
                      </div>
                      {filter.date_range[0 ]=== 0 ?
                        <React.Fragment>
                        <div className="row">
                          <div className="form-group form-group-default" style={{overflow:'visible'}}>
                            <label>Date Start:</label>
                            <DatePicker
                              className="form-control"
                              selected={start_date}
                              onChange={this.setStart}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group form-group-default" style={{overflow:'visible'}}>
                            <label>Date End:</label>
                            <DatePicker
                              className="form-control"
                              selected={end_date}
                              onChange={this.setEnd}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>
                        </div>
                        </React.Fragment>
                      : null}
                      <div className="row m-t-10">
                        <div className="col-4 p-b-10">
                        </div>
                        <div className="col-8">
                          <button className={analysisLoading ? "btn btn-primary pull-right btn-lg btn-block disabled" : "btn btn-primary pull-right btn-lg btn-block"} type="button" onClick={this.runAnalysis}>{analysisLoading ? 'Generating Please Wait' : 'Generate Analysis' }</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-9 col-sm-12">
                  <AnalysisDetail analysis={selected_analysis} profile={profile} getResults={this.getResults} loadReviews={this.loadReviews} pdf={pdf}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>);
  }
}

export default ProfileDetail;
