import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { connectionGet, connectionPost, connectionPut } from '../utils/connection';
import Modal from 'react-bootstrap4-modal';
import ProfileModalAdd2 from './ProfileModalAdd2';
import AnalysisModal from './AnalysisModal';
import BootstrapTable from 'react-bootstrap-table-next';
import {Spinner} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';


let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

class Profile{
  name = '';
  description = '';
  aspect_custom_id = 0;
  sys_schedule_id = 0;
  description = '';
  entity_list = []
}

@inject('userStore')
@observer
class ProfileList extends Component{
  constructor(props){
    super(props);
    this.state = {
      visible:false,
      analysis_visible:false,
      selected_profile:null,
      account:null,
      profileList:[],
      profile:new Profile(),
      aspect_custom_list:[],
      sys_schedule_list:[],
      sys_review_source_list:[],
      loading:false
    }
  }

  componentDidMount(){
    this.fetchAccount();
  }

  openAnalysis = (analysis) => {
    this.fetchAnalysis(analysis);
  }

  modalBackgroundClicked = () => {
    this.setState({
      visible:false,
      analysis_visible:false
    });
  }

  handleProfileChange = (e) => {
    let tmp_profile = Object.assign({}, this.state.profile);
    const value = e.target.value;
    tmp_profile[e.target.name] = value;
    this.setState({profile:tmp_profile});
  }

  addEntity = (entity) => {
    let tmp_profile = Object.assign({}, this.state.profile);
    tmp_profile.entity_list.push(entity);
    this.setState({profile:tmp_profile});
  }

  submit = (profile) => {
    if (profile.id === 0){
      connectionPost('profiles', {profile: profile}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          this.setState({
            profileList:res.data.profile_list,
            visible:false
          }, ()=>{
            toast.success(res.data.message, toastParams);
          })
        } else {
          toast.error(res.data.message, toastParams);
        }
      }).catch((error) => {
        console.log(error);
        return error;
      });
    } else {
      connectionPut('profiles', {profile: profile}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          this.setState({
            profileList:res.data.profile_list,
            visible:false
          }, ()=>{
            toast.success(res.data.message, toastParams);
          })
        } else {
          toast.error(res.data.message, toastParams);
        }
      }).catch((error) => {
        console.log(error);
        return error;
      });
    }
  }

  fetchAnalysis = (profile) => {
    connectionGet('profiles/' + profile.hash_id + '/analysis/' + profile.last_analysis.hash_id, {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        toast.success(res.data.message, toastParams);
        this.setState({
          selected_profile:profile,
          analysis_visible:true,
          analysis:res.data.profile_analysis
        }, console.log(this.state.selected_profile))
      } else {
        toast.error(res.data.message, toastParams);
      }
    })
    .catch((error)=>{toast.error(error.response.statusText, toastParams);});
  }

  fetchAccount = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('v2/profiles', {}, {username: this.props.userStore.token}
      ).then((res) => {
        this.setState({
          profileList:res.data.profile_list,
          aspect_custom_list:res.data.aspect_custom_list,
          sys_schedule_list:res.data.sys_schedule_list,
          sys_review_source_list:res.data.sys_review_source_list,
          loading:false
        });
      }).catch((error) => {
        return error;
      });
    });
  }

  openAddProfile = () => {
    this.setState({
      visible:true,
      analysis_visible:false
    })
  }

  render(){
    const {profileList, profile, aspect_custom_list, sys_schedule_list, sys_review_source_list, loading, selected_profile, analysis} = this.state;
    const columns = [{
      dataField: 'title',
      text: 'Title',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.name}
          </div>
        );
      }
    },{
      dataField: 'last_analysis',
      isDummyField: true,
      text: 'Last Analysis',
      formatter: (cellContent, row) => {
        if (row.last_analysis){
          return (
            <React.Fragment>
            <button className="btn btn-success" onClick={()=>this.openAnalysis(row)} title={row.last_analysis.name}><i className="pg-icon m-r-5">chart_alt</i> Last Analysis</button>
            <br/>
            <small className="d-inline-block text-hint text-truncate" style={{maxWidth:'100%'}}>{row.last_analysis.name}</small>
            </React.Fragment>
          );
        } else {
          return (
            <span className="label label-info">No Analysis Records</span>
          )
        }
      }
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <div className="dropdown dropdown-default full-width">
            <button aria-label="" className="btn dropdown-toggle full-width text-truncate d-inline-block" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="semi-bold">Actions</span>
            </button>
            <div className="dropdown-menu">
              <Link to={{ pathname: '/prism/profiles/' + row.hash_id }} className="dropdown-item"><i className="pg-icon">chart_alt</i> All Analysis</Link>
              <Link to={{ pathname: '/prism/profiles/reviews/' + row.hash_id }} className="dropdown-item"><i className="pg-icon">comment</i> Reviews</Link>
              <Link to={{ pathname: '/prism/profiles/settings/' + row.hash_id }} className="dropdown-item"><i className="pg-icon">settings</i> Settings</Link>
            </div>
          </div>
        );
      }
    }];
    return (
      <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
      <Modal visible={this.state.analysis_visible} onClickBackdrop={this.modalBackgroundClicked} dialogClassName="modal-lg">
        {selected_profile ?
          <AnalysisModal modalBackgroundClicked={this.modalBackgroundClicked} profile={selected_profile} analysis={analysis}/>
         :
          <div/>
        }
      </Modal>
      <Modal visible={this.state.visible} onClickBackdrop={this.modalBackgroundClicked} dialogClassName="modal-lg">
          <ProfileModalAdd2 modalBackgroundClicked={this.modalBackgroundClicked} aspect_custom_list={aspect_custom_list} sys_schedule_list={sys_schedule_list} sys_review_source_list={sys_review_source_list} submitHandler={this.submit}/>
      </Modal>
      <div className="no-padding container-fixed-lg bg-white">
        <div className="container">
          <div className="card card-transparent">
            <div className="card-header p-l-0">
              <div className="card-title">
                <h4>Profiles</h4>
              </div>
              <div className="card-controls">
              <button onClick={this.openAddProfile} className="btn btn-sm btn-success">Add Profile</button>
              </div>
            </div>
            <div className="card-body p-l-0 p-r-0 p-b-200">
              <div className="table-responsive" style={{minHeight:'550px'}}>
              {loading ?
                <div className="card-body">
                  <h4><Spinner color="primary"/> Loading...</h4>
                </div>
              :
                <BootstrapTable keyField='id' classes='table-condensed table table-striped table-hover table-bordered' data={profileList} columns={columns} striped hover headerClasses="thead-light"/>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default ProfileList;
