import { observable, computed, action } from "mobx";
import { connectionPost } from '../utils/connection';
import { create, persist } from 'mobx-persist';
import { auth } from '../firebase';

class UserStore {
    @persist @observable name = '';
    @persist @observable email = '';
    @persist @observable password = '';
    @persist @observable firebase_uid = '';
    @persist @observable hash_id = '1';
    @persist @observable token = '';
    @persist @observable id = 0;
    @persist @observable is_admin = 0;
    @persist @observable is_prism = 0;
    @persist @observable show_prism = 0;
    @persist('list') @observable thingList = [];

    @computed get is_authenticated() {
    	if (this.token !== ''){
    		return this.token;
    	} else {
    		return false;
    	}
    }

	  @action get_token(fire_uid){
      return connectionPost('token', {}, {username: fire_uid, password: ''}
			).then((res) => {
        if (res.data.token){
          // should return: user, order list, favorites,
          this.name = res.data.account.first_name;
          this.email = res.data.account.email;
          this.id = res.data.account.id;
          this.firebase_uid = res.data.account.firebase_uid;
          this.token = res.data.token;
          this.thingList = res.data.account.thing_list
          this.is_admin = res.data.account.is_admin;
          this.is_prism = res.data.account.is_prism;
          this.hash_id = res.data.account.hash_id;
          this.show_prism = 0;
        }
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    }

    @action setToken(newtoken){
      this.token = newtoken;
    }

    @action switchSite(){
      console.log(this.show_prism);
      this.show_prism === 1 ? this.show_prism = 0 : this.show_prism = 1;
    }

    @action logout(){
      auth.doSignOut().then(()=>{
        this.name = '';
        this.email = '';
        this.id = 0;
        this.firebase_uid = '';
        this.token = '';
        this.thingList = [];
        this.hash_id = '';
        this.is_admin = 0;
        this.is_prism = 0;
        this.show_prism = 0;
        window.location.href = '/';        
      });
    }
}

export default new UserStore();
