import React, { Component } from 'react';
import { connectionGet } from '../utils/connection';
import { inject } from 'mobx-react';
import {Spinner} from 'reactstrap';

class ReviewSource{
  sys_review_source_id = 0;
  url = '';
}

class Profile{
  name = '';
  exists = false;
  id = 0;
  aspect_custom_id = 0;
  description = '';
  review_source_list = [];
  thing_id_list = [];
  owner_account_id = 0;
  sys_schedule_id = 0;
}

@inject('userStore')
class ProfileModalAdd extends Component {
  constructor(props){
    super(props);
    this.state = {
      profile:new Profile(),
      thing_list:[],
      owner_list:[],
      loading:true
    }
  }

  componentDidMount(){
    this.fetchThings();
  }

  componentWillReceiveProps(nextProps){
    this.fetchThings();
  }

  fetchThings = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('v2/things', {}, {username: this.props.userStore.token}
      ).then((res) => {
        this.setState({
          thing_list:res.data.thing_list,
          owner_list:res.data.owner_list,
          loading:false
        });
      }).catch((error) => {
        return error;
      });
    });
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.profile);
    let value = e.target.value;
    if (e.target.type === 'checkbox'){
      value = parseInt(value);
      if (e.target.checked){
        updated_obj.thing_id_list.push(value);
      } else {
        // remove if included
        var index = updated_obj.thing_id_list.indexOf(value);
        if (index !== -1) updated_obj.thing_id_list.splice(index, 1);
      }
    }
    updated_obj[e.target.name] = value;
    this.setState({profile:updated_obj},()=>{console.log(this.state.profile)});
  }

  render(){
    const {profile, thing_list, owner_list, loading} = this.state;
    const {aspect_custom_list, sys_schedule_list, submitHandler, modalBackgroundClicked} = this.props;
    const isInvalid = profile.name === '' && profile.aspect_custom_id === 0 && profile.sys_schedule_id === 0 && profile.thing_id_list.length === 0;
    return (
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="m-t-0"><i className="pg-icon">alert</i>{profile.id === 0 ? 'Add' : 'Edit'} Profile Information</h3>
          </div>
          <div className="modal-body">
            <form role="form" id="form-register">
              <div className="form-group-attached">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Name</label>
                      <input type="text" name="name" placeholder="" className="form-control" value={profile.name} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group form-group-default">
                      <label>Description</label>
                      <textarea name="description" placeholder="" className="form-control" value={profile.description} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Aspect Dictionary</label>
                      <select name="aspect_custom_id" className="form-control" value={profile.aspect_custom_id} onChange={this.handleInputChange}>
                        <option>Select Aspect</option>
                        {aspect_custom_list.map((aspect, aspect_index)=>
                          <option index={aspect_index} value={aspect.id}>{aspect.name}</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Analysis Schedule</label>
                      <select name="sys_schedule_id" className="form-control" value={profile.sys_schedule_id} onChange={this.handleInputChange}>
                        <option>Select Aspect</option>
                        {sys_schedule_list.map((sys_schedule, sys_schedule_index)=>
                          <option index={sys_schedule_index} value={sys_schedule.id}>{sys_schedule.name}</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Profile Owner</label>
                      <select name="owner_account_id" className="form-control" value={profile.owner_account_id} onChange={this.handleInputChange}>
                        <option>Select Owner</option>
                        {owner_list.map((owner, owner_index)=>
                          <option index={owner_index} value={owner.account_id}>{owner.last_name} {owner.first_name}</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label className="p-b-10">Entities to Include</label>
                      {loading ?
                          <strong><Spinner color="primary"/> Loading Entities...</strong>
                      :
                        thing_list.map((thing, thing_index)=>
                          <div key={thing_index}  className="form-check primary">
                            <input type="checkbox" name="thing_add_cb" id={thing_index} onChange={this.handleInputChange} value={thing.thing_id} checked={profile.thing_id_list && profile.thing_id_list.includes(thing.id) ? 'checked' : null}/>
                            <label htmlFor={thing_index}>{thing.name}</label>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="row m-t-20">
              <div className="col-md-6 offset-md-6">
                <div className="btn-group pull-right">
                  <button aria-label="" type="button" className="btn btn-danger" onClick={modalBackgroundClicked}>Cancel</button>
                  <button aria-label="" type="submit" className={isInvalid ? "btn btn-success disabled" : "btn btn-success"} onClick={isInvalid ? ()=>{return false;} : ()=> submitHandler(profile)}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default ProfileModalAdd;
