import React, { Component } from 'react';
import ProfileAnalysisTable from './ProfileAnalysisTable';
import ProfileAnalysisWords from './ProfileAnalysisWords';
import RadarChart from '../charts/RadarChart';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class AnalysisDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageNum:1,
      numberPages:0
    }
  }

  onDocumentLoadSuccess = (pdfinfo) => {
    this.setState({
      numberPages:pdfinfo.numPages
    });
  }

  openPDF = (report) => {
    const blob = new Blob([report.data], { type: 'application/pdf' })
    window.open(URL.createObjectURL(blob));
  }

  render(){
    const {analysis, getResults, loadReviews, profile, pdf} = this.props;
    const {pageNum, numberPages} = this.state;
    let report = null;
    if (pdf){
      report = pdf;
    }
    return (
      <React.Fragment>
      <h5>{analysis ? analysis.name : null}</h5>
      <ul className="nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex" role="tablist" data-init-reponsive-tabs="dropdownfx">
        <li className="nav-item">
          <a className="active" data-toggle="tab" data-target="#table" href="#"><span>Table</span></a>
        </li>
        <li className="nav-item">
          <a data-toggle="tab" data-target="#radar" href="#"><span>Radar Chart</span></a>
        </li>
        <li className="nav-item">
          <a data-toggle="tab" data-target="#word" href="#"><span>Word Cloud</span></a>
        </li>
        <li className="nav-item">
          <a data-toggle="tab" data-target="#pdf" href="#"><span>PDF</span></a>
        </li>
      </ul>
      <div className="nav-tab-dropdown cs-wrapper full-width d-lg-none d-xl-none d-md-none">
        <div className="cs-select cs-skin-slide full-width" tabIndex="0">
          <span className="cs-placeholder">Table</span>
          <div className="cs-options">
            <ul>
              <li data-option="" data-value="#table"><span>Graph</span></li>
              <li data-option="" data-value="#radar"><span>Reviews</span></li>
              <li data-option="" data-value="#word"><span>Word Cloud</span></li>
            </ul>
          </div>
          <select className="cs-select cs-skin-slide full-width" data-init-plugin="cs-select" defaultValue={"#table"}>
            <option value="#table">Table</option>
            <option value="#radar">Radar</option>
            <option value="#cloud">Cloud</option>
          </select>
          <div className="cs-backdrop"></div>
        </div>
      </div>
      <div className="tab-content">
        <div className="tab-pane active" id="table">
          <ProfileAnalysisTable analysis={analysis} getResults={getResults} loadReviews={loadReviews} profile={profile}/>
        </div>
        <div className="tab-pane" id="radar">
        {analysis && analysis.radar_data_set ?
          <RadarChart data={analysis.radar_data_set}/>
        :
          null
        }
        </div>
        <div className="tab-pane" id="word">
        {analysis ?
          <ProfileAnalysisWords analysis_hash_id={analysis.hash_id} profile={profile}/>
        : null}
        </div>
        <div className="tab-pane b b-grey" id="pdf">
        {report ?
          <React.Fragment>
            <button className="btn btn-sm btn-primary" onClick={()=>this.openPDF(report)}>View/Print/Save PDF</button>
            <Document file={report} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={(error)=>console.log(error.message)} onSourceError={(error)=>console.log(error.message)}>
              {Array.from(
                new Array(numberPages),
                (el, index) => (
                  <Page
                    scale={1.2}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ),
              )}
            </Document>
          </React.Fragment>
        :
          null
        }
        </div>
      </div>
      </React.Fragment>
    )
  }
}

export default AnalysisDetail;
