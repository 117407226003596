import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { connectionGet, connectionPost } from '../utils/connection';
import AnalysisFlagRow from './AnalysisFlagRow';

@inject('userStore')
class ReviewFlags extends Component {
  constructor(props){
    super(props);
    this.state = {
      flag_list:[]
    }
  }

  componentDidMount() {
    this.fetchSources();
  }

  fetchSources = () => {
    connectionGet('flag-reviews', {}, {username: this.props.userStore.token}
    ).then((res) => {
      this.setState({
        flag_list:res.data.flags
      });
    }).catch((error) => {
      return error;
    });
  }

  vaderHandler = (sentence) => {
    connectionPost('/post/vader', {'text':sentence}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
      alert(res.data.sentiment);
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Review Analysis Flag List</h4>
          </div>
          {this.state.flag_list ? this.state.flag_list.map((flag, index) =>
            <AnalysisFlagRow key={index} flag={flag} vaderHandler={this.vaderHandler} quickviewHandler={()=>{return false;}}/>
          ) : "Loading"}
        </div>
      </React.Fragment>
    );
  }
}

export default ReviewFlags;
