import React, { Component } from 'react';

class FormattedReviewText extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render(){
    const {review} = this.props;
    return (
      <React.Fragment>
        <p className="aspect-p">
        {review.analyzed.analysis.map((sentence, index) => {
            var aspects = '';
            Object.entries(sentence.topic_classified).forEach(topic => {
              if (topic[1].length > 0 && topic[1][1] > 1){
                aspects = aspects.concat(" | " + topic[1][0]);
              }
            });
            var style = '';
            var className = '';
            var sentimentScore = Math.round(sentence.sentiment[0] * 100)/100;
            if (sentimentScore > 0){
              style = {backgroundColor: 'rgba(0, 255, 0, ' + sentimentScore + ')'}
              className = 'cursor-pointer aspect aspect-sentiment-positive';
            } else if (sentimentScore < 0) {
              style = {backgroundColor: 'rgba(255, 0, 0, ' + Math.abs(sentimentScore) + ')'}
              className = 'cursor-pointer aspect aspect-sentiment-negative';
            } else {
              style = {backgroundColor: 'rgba(255, 255, 0, 0.1)'}
              className = 'cursor-pointer aspect aspect-sentiment-neutral';
            }
            return (
              <React.Fragment key={index}>
                <mark title={sentence.sentiment[0] + ' | ' + aspects} className={className} style={style}>
                  {sentence.sentence}
                </mark>
              </React.Fragment>);
        })}
        </p>
      </React.Fragment>
    );
  }
}

export default FormattedReviewText;
