import React, {Component} from 'react';
import InputMask from 'react-input-mask';
import { connectionGet, connectionPost, connectionPut } from '../utils/connection';
import {inject} from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class Settings extends Component{
  constructor(props){
    super(props);
    this.state = {
      account:this.props.userStore,
      passwordMatch:true
    }
  }

  handleInputChange = (e) => {
    let tmp = Object.assign({}, this.state.account);
    let value = e.target.value;
    tmp[e.target.name] = value;
    if (e.target.name === 'password_verify'){
      if (value !== tmp.new_password){
        this.setState({passwordMatch:false})
      } else {
        this.setState({passwordMatch:true})
      }
    }
    this.setState({account:tmp});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    connectionPut('users/' + this.props.userStore.hash_id, {user:this.state.account}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        toast.success(res.data.message, toastParams);
        this.props.userStore.name = res.data.user.full_name;
        this.props.userStore.first_name = res.data.user.first_name;
        this.props.userStore.last_name = res.data.user.last_name;
        this.props.userStore.email = res.data.user.email;
      } else {
        toast.error(res.data.message, toastParams);
      }
    })
  }

  render(){
    if (!this.state.account) return <div>Loading</div>;
    const {account, passwordMatch} = this.state;
    const isInvalid = account.first_name === '' && account.last_name === '' && account.email === '';
    return (
      <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <div className="modal-header clearfix text-left">
              <button aria-label="" type="button" className="close" onClick={this.props.onClickBackdrop} aria-hidden="true"><i className="pg-icon">close</i>
              </button>
              <h5>Account <span className="semi-bold">Settings</span> - {account.full_name}</h5>
              <p className="p-b-10">Make changes to your account.  Leave Password fields blank if you are not updating.</p>
            </div>
            <div className="modal-body">
              <form role="form" id="form-register" onSubmit={this.handleSubmit}>
                <div className="form-group-attached">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group form-group-default">
                        <label>First Name</label>
                        <input type="text" name="first_name" placeholder="" className="form-control" required value={account.first_name} onChange={this.handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group form-group-default">
                        <label>Last Name</label>
                        <input type="text" name="last_name" placeholder="" className="form-control" required value={account.last_name} onChange={this.handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-group-default">
                        <label>Email</label>
                        <input type="email" name="email" placeholder="" className="form-control" value={account.email} onChange={this.handleInputChange}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group form-group-default">
                        <label>New Password</label>
                        <input type="password" name="new_password" placeholder="" className="form-control" value={account.new_password ? account.new_password : ''} onChange={this.handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-group-default">
                        <label>New Password Verify</label>
                        <input type="password" name="password_verify" placeholder="" className="form-control" value={account.password_verify} onChange={this.handleInputChange}/>
                        {passwordMatch ? null : <p className="text-danger">Passwords must match</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-t-5">
                  <div className="col-12">
                    <div className="btn-group pull-right">
                      <button className="btn btn-default" type="button">Cancel</button>
                      <button className={isInvalid ? "btn btn-primary disabled" : "btn btn-primary"} type="submit">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
    </React.Fragment>
    )
  }
}

export default Settings;
