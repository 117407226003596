import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { Link,withRouter } from "react-router-dom";
import ThingForm from './ThingForm';
import ThingDetailModal from './ThingDetailModal';
import AspectForm from './AspectForm';
import AdminThingDetail from './AdminThingDetail';
import ThingAccess from '../revu/ThingAccess';
import {Spinner} from 'reactstrap';
import { inject } from 'mobx-react';
import { connectionGet, connectionPost, connectionPut, connectionDelete } from '../utils/connection';

@inject('userStore')
class Things extends Component {
  constructor(props){
    super(props);
    this.state = {
      thing_list:[],
      visible:false,
      thing_edit:null,
      question_set_list:[],
      incentive_list:[],
      aspect:null,
      thing_detail:null,
      detail_component:null,
      account_list:[],
      loading:true
    }
  }

  componentDidMount() {
    this.fetchThings();
  }

  editThing = (thing) => {
    console.log(thing)
    connectionGet('accounts/' + thing.account_holder.hash_id, {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
      this.setState({
        question_set_list:res.data.question_set_list,
        incentive_list:res.data.incentive_list,
        visible:!this.state.visible,
        thing_edit:thing
      });
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  addThing = () => {
    let thing = {id:0, name:'', unique_name:'', sys_thing_type_id:1, days_between:null, owner_account_id:0}
    this.setState({
      question_set_list:null,
      incentive_list:null,
      thing_edit_visible:true,
      thing_edit:thing
    });
  }

  viewThingDetail = (thing) => {
    connectionGet('things/' + thing.hash_id, {shortDict:1}, {username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        this.setState({
          visible:!this.state.visible,
          thing_detail:res.data.thing,
          detail_component:<AdminThingDetail thing={res.data.thing} sys_review_source_list={res.data.sys_review_source_list}/>
        });
      }
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  viewThingAccess = (thing) => {
    this.setState({
      visible:!this.state.visible,
      thing_detail:thing,
      detail_component:<ThingAccess item={thing} sys_role_list={this.state.sys_role_list} addUser={this.addUser} removeAccess={this.removeAccess}/>
    });
  }

  editAspects = (aspect_custom_id) => {
    connectionGet('aspect-custom/' + aspect_custom_id, {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
      this.setState({
        aspect:res.data.aspect_custom,
        visible:!this.state.visible
      });
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  modalBackdropClicked = () => {
    this.setState({
      thing_edit_visible:false,
      visible:false,
      aspect:null,
      thing_edit:null,
      thing_detail:null,
      detail_component:null
    })
  }

  handleSubmit = () => {
    if (this.state.thing_edit){
      if (this.state.thing_edit.id != 0){
        connectionPut('things/' + this.state.thing_edit.hash_id, {thing:this.state.thing_edit}, {username: this.props.userStore.token}
        ).then((res) => {
          console.log(res.data);
          if (res.data.errors === 0){
            // add to list or update item
            let tmp_thing_list = Object.assign([], this.state.thing_list);
            if (this.state.thing_edit.id === 0){
              // add the returned item to the list of things
              tmp_thing_list.push(res.data.item);
            } else {
              // modify the particular item
              let index = this.state.thing_list.findIndex(x => x.id === res.data.item.id);
              tmp_thing_list[index] = res.data.item;
            }
            this.setState({
              thing_edit:null,
              visible:false,
              thing_list:tmp_thing_list
            })
          }
        }).catch((error) => {
          console.log(error);
          return error;
        });
      } else {
        connectionPost('things', {thing:this.state.thing_edit}, {username: this.props.userStore.token}
        ).then((res) => {
          console.log(res.data);
          if (res.data.errors === 0){
            // add to list or update item
            let tmp_thing_list = Object.assign([], this.state.thing_list);
            if (this.state.thing_edit.id === 0){
              // add the returned item to the list of things
              tmp_thing_list.push(res.data.item);
            } else {
              // modify the particular item
              let index = this.state.thing_list.findIndex(x => x.id === res.data.item.id);
              tmp_thing_list[index] = res.data.item;
            }
            this.setState({
              thing_edit:null,
              visible:false,
              thing_list:tmp_thing_list
            })
          }
        }).catch((error) => {
          console.log(error);
          return error;
        });
      }
    }
  }


  handleAspectSubmit = () => {
    if (this.state.aspect){
      connectionPut('aspect-custom/' + this.state.aspect_custom.id, {aspect_custom:this.state.aspect}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        if (res.data.errors === 0){
          this.setState({
            aspect:null,
            visible:false
          })
        }
      }).catch((error) => {
        console.log(error);
        return error;
      });
    }
  }

  handleAspectChange = (value) => {
    let updatedItem = Object.assign({}, this.state.aspect);
    updatedItem.aspects = value;
    console.log(updatedItem.aspects);
    this.setState({aspect:updatedItem});
  }


  handleInputChange = (e) => {
    let updated_thing = Object.assign({}, this.state.thing_edit);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updated_thing[e.target.name] = value;
    console.log(updated_thing)
    this.setState({thing_edit:updated_thing});
  }

  fetchThings = () => {
    this.setState({
      loading:true
    },()=>{
      connectionGet('things', {}, {username: this.props.userStore.token}
      ).then((res) => {
        this.setState({
          thing_list:res.data.thing_list,
          account_list:res.data.account_list,
          available_account_list:res.data.account_list,
          sys_role_list:res.data.sys_role_list,
          loading:false,
          sys_review_source_list:res.data.sys_review_source_list
        });
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  addUser = (user) => {
    connectionPost('things/' + this.state.thing_detail.hash_id + '/account', {user:user}, { username: this.props.userStore.token}
    ).then((res) => {
      alert(res.data.message);
      let tmp_thing_detail = Object.assign([], this.state.thing_detail);
      tmp_thing_detail.account_list.push(res.data.item);
      this.setState({
        thing_detail:tmp_thing_detail
      });
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  removeAccess = (user) => {
    connectionDelete('things/' + this.state.thing_detail.hash_id + '/account', {account_id:user.account_id}, { username: this.props.userStore.token}
    ).then((res) => {
      alert(res.data.message);
      let tmp_thing_detail = Object.assign([], this.state.thing_detail);
      let tmp_account_list = tmp_thing_detail.account_list.filter(x => x.account_id === user.account_id);
      tmp_thing_detail.account_list = tmp_account_list;
      this.setState({
        thing_detail:tmp_thing_detail
      });
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  generateData = (thing) => {
    connectionPost('thing-generate/' + thing.id, {}, { username: this.props.userStore.token}
    ).then((res) => {
      alert(res.data.message);
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  render() {
    const {thing_edit_visible, visible, thing_edit, question_set_list, incentive_list, aspect, thing_detail, detail_component, account_list, loading, sys_role_list} = this.state;
    return (
    <div className="container">
      <Modal visible={thing_edit_visible} onClickBackdrop={this.modalBackdropClicked} dialogClassName="modal-lg">
        <ThingDetailModal submit={this.handleSubmit} thing={thing_edit} handleInputChange={this.handleInputChange} question_set_list={question_set_list} incentive_list={incentive_list} account_list={account_list}/>
      </Modal>

      <Modal visible={visible} onClickBackdrop={this.modalBackdropClicked} dialogClassName="modal-lg">
        {thing_edit ?
          <React.Fragment>
            <div className="modal-header">
                <h4 className="modal-title">{thing_edit.name}</h4>
                <button type="button" className="close" onClick={this.modalBackdropClicked} aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
              <ThingForm thing={thing_edit} handleInputChange={this.handleInputChange} question_set_list={question_set_list} incentive_list={incentive_list} account_list={account_list}/>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={this.modalBackdropClicked}>
                  Close
                </button>
                <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>
                  Save
                </button>
            </div>
        </React.Fragment>
        :
        aspect ?
        <React.Fragment>
          <div className="modal-header">
              <h4 className="modal-title">{aspect.industry}</h4>
              <button type="button" className="close" onClick={this.modalBackdropClicked} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body">
            <AspectForm aspect={aspect} handleAspectChange={this.handleAspectChange}/>
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={this.modalBackdropClicked}>
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={this.handleAspectSubmit}>
                Save
              </button>
          </div>
        </React.Fragment>
        :
        thing_detail ?
          detail_component
        :
        <div>No Thing Loaded</div> }
      </Modal>
      <div className="card">
        <div className="card-header separator m-b-15">
          <h4 className="card-title">Thing List</h4>
          <div className="card-controls">
            <ul>
              <li>
                <div className="dropdown">
                  <a id="card-settings" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                    <i className="card-icon card-icon-settings "></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings">
                    <a href="#" className="dropdown-item" onClick={this.addThing}>Add New</a>
                  </div>
                </div>
              </li>
              <li><a href="#" className="card-refresh" data-toggle="refresh" onClick={this.fetchThings}><i className="card-icon card-icon-refresh"></i></a></li>
            </ul>
          </div>
        </div>
        <div className="card-body table-responsive-sm">
        {loading ?
          <div className="card-body">
            <h4><Spinner color="primary"/> Loading...</h4>
          </div>
        :
          <table className="table table-bordered table-condensed">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Account Owner</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {this.state.thing_list ? this.state.thing_list.map((thing, index) =>
              <tr key={index}>
                <td>{thing.name}</td>
                <td>{thing.sys_thing_type}</td>
                <td>{thing.account_holder ? thing.account_holder.account_name : 'NA'}</td>
                <td>
                    <div className="btn-group-vertical">
                      <button className="btn btn-sm btn-default" onClick={()=>this.generateData(thing)}>Generate Data</button>
                    {thing.account_holder ?
                      <button className="btn btn-sm btn-default" onClick={()=>this.editThing(thing)}>Edit</button>
                    : null}
                    {thing.aspect_custom_id ?
                      <button className="btn btn-sm btn-default" onClick={()=>this.editAspects(thing.aspect_custom_id)}>Aspects</button>
                    : null}
                      <button className="btn btn-sm btn-default" onClick={()=>this.viewThingDetail(thing)}>Details</button>
                      <button className="btn btn-sm btn-default" onClick={()=>this.viewThingAccess(thing)}>Access</button>
                    </div>
                </td>
              </tr>
            ) : "Loading"}
            </tbody>
          </table>
        }
        </div>
      </div>
    </div>
    );
  }
}

export default Things;
