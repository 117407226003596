import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import InputMask from 'react-input-mask';

class ThingAccess extends Component{
  constructor(props){
    super(props);
    var generator = require('generate-password');
    var password = generator.generate({
        length: 10,
        numbers: true
    });
    this.state = {
      loading:true,
      hideInactive:true,
      user:{id:0, account_id:0, first_name:'', last_name:'', email:'', password:password, sys_role_id:'', is_email:0, sys_status_id:''}
    }
  }

  handleInputChange = (e) => {
    let updated_user = Object.assign({}, this.state.user);
    const value = e.target.value;
    updated_user[e.target.name] = value;
    this.setState({user:updated_user},()=>{console.log(this.state.user)});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addUser(this.state.user);
    this.reset();
  }

  removeAccess = (e, row) => {
    e.preventDefault();
    this.props.removeAccess(row);
  }

  reset = () => {
    var generator = require('generate-password');
    var password = generator.generate({
        length: 10,
        numbers: true
    });
    this.setState({
      user:{id:0, account_id:0, first_name:'', last_name:'', email:'', password:password, sys_role_id:'', is_email:0, sys_status_id:''}
    })
  }

  editUser = (user) => {
    let tmp_user = Object.assign({}, this.state.user);
    tmp_user = user;
    this.setState({
      user:tmp_user
    })
  }

  render(){
    if (!this.props.item) return <div>Loading</div>;
    const {hideInactive, backgroundClickHandler, sys_role_list, item} = this.props;
    const {user} = this.state;
    const {available_account_list, account_list} = this.props.item;
    const isInvalid = user.email === '' && user.first_name === '' && user.last_name === '' && user.password === '';
    let rows = this.props.item.account_list;
    const columns = [{
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.account_name}
          </div>
        );
      }
    }, {
      dataField: 'email',
      text: 'Email',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.account_email}
          </div>
        );
      }
    }, {
      dataField: 'sys_role',
      text: 'Role',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <span className="badge badge-default">{row.sys_role}</span>
        )
      }
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <button className="btn btn-primary" type="button" onClick={(e)=>{this.removeAccess(e, row)}}>Remove</button>
        );
      }
    }];
    return (
      <div className="padding-20">
        <h3 className="m-t-0"><i className="pg-icon">users</i> Access</h3>
        <form role="form" id="form-profile-account" onSubmit={this.handleSubmit}>
          {user.id !== 0 ?
            <h3>Update user access</h3>
          :
            <h3>Add user access</h3>
          }
          <div className="form-group-attached">
            <div className="row">
              <div className="col-md-6">
              {user.id !== 0 ?
                <div className="form-group form-group-default">
                  <label>Selected Account</label>
                  <input type="text" className="form-control"  value={user.email} disabled/>
                </div>
              :
                <div className="form-group form-group-default">
                  <label>Account</label>
                  <select className="form-control" name="account_id" value={user.account_id} onChange={this.handleInputChange}>
                    <option value="">Select Account</option>
                    {available_account_list ? available_account_list.map((account, acc_index)=>
                      <option key={acc_index} value={account.id}>{account.email}</option>
                    ) : null}
                  </select>
                </div>
              }
              </div>
              <div className="col-md-6">
                <div className="form-group form-group-default">
                  <label>Roles</label>
                  {sys_role_list.map((role, role_index)=>
                    <div key={role_index}  className="form-check primary">
                      <input type="radio" name="sys_role_id" id={role_index} onChange={this.handleInputChange} value={role.id} checked={parseInt(user.sys_role_id) === role.id ? 'checked' : null}/>
                      <label htmlFor={role_index}>{role.name}: <small className="text-hint fs-10">{role.description}</small></label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-t-10 m-b-5">
            <div className="col-12">
              <div className="btn-group pull-right">
                <button className={isInvalid ? "btn btn-primary disabled" : "btn btn-primary"} type="submit">Add User</button>
              </div>
            </div>
          </div>
        </form>
        <div className="table-responsive">
          <BootstrapTable keyField='id' classes='table-condensed table table-striped table-hover table-bordered' data={rows} columns={columns} striped hover headerClasses="thead-light"/>
        </div>
    </div>
    )
  }
}

export default ThingAccess;
