import React, { Component } from 'react';
import {Spinner} from 'reactstrap';
import ReviewBlock from '../common/ReviewBlock';

class AnalysisReviewsQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  render(){
    const {qvTitle, loading, review_list, backgroundClickHandler} = this.props;
    return (
      <React.Fragment>
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header clearfix text-left">
            <button aria-label="" type="button" className="close" onClick={backgroundClickHandler} aria-hidden="true"><i className="pg-icon">close</i>
            </button>
            <h5>{loading ? 'Loading' : qvTitle}</h5>
          </div>
          <div className="modal-body">
            <div className="row col-12 media-list media-list-divided">
            {loading ?
              <h4><Spinner color="primary"/> Loading...</h4>
            : review_list.length > 0 ?
              review_list.map((review, index)=>
                <ReviewBlock key={index} review={review}/>
              )
            : <div>No Reviews Found</div>}
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default AnalysisReviewsQuickview;
