import React, { Component } from 'react';
import {HorizontalBar} from 'react-chartjs-2';

class HBarChart extends Component {
  render() {
    const {data} = this.props;
    const options = {
      maintainAspectRatio: true,
      scales: {
        xAxes: [{ stacked: true }],
        yAxes: [{ stacked: true }]
      }
    };
    return (
    	<HorizontalBar
        data={data}
        width={900}
        height={650}
        options={options}/>
    );
  }
}

export default HBarChart;
