import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { connectionPost, connectionPut } from '../utils/connection';

@inject('userStore')
class AdminThingDetail extends Component{
    constructor(props){
        super(props);
        this.state = {
          thing:null,
          mode:'view',
          source:null
        }
    }

    componentDidMount(){
      if (this.props.mode === 'add'){
        this.setState({
          mode:'add',
          thing:this.props.thing,
          source:{id:0,url:'',sys_review_source_id:1,thing_id:this.props.thing.id}
        })
      } else {
        this.setState({
          thing:this.props.thing
        })
      }
    }

    addSource = () => {
      this.setState({
        mode:'add',
        source:{id:0,url:'',sys_review_source_id:1,thing_id:this.state.thing.id}
      })
    }

    editSource = (source) => {
      this.setState({
        mode:'edit',
        source:source
      })
    }

    saveSource = () => {
      if (this.state.mode === 'add'){
        console.log('posting');
        connectionPost('things/' + this.state.thing.hash_id + '/review_source', {source:this.state.source}, {username: this.props.userStore.token}
        ).then((res) => {
          if (res.data.errors === 0){
            this.setState({
              thing:res.data.thing,
              mode:'view',
              source:null
            })
          } else {
            alert(res.data.message);
          }
        }).catch((error) => {
          console.log(error);
          return error;
        });
      } else {
        connectionPut('things/' + this.state.thing.hash_id + '/review_source', {source:this.state.source}, {username: this.props.userStore.token}
        ).then((res) => {
          if (res.data.errors === 0){
            this.setState({
              thing:res.data.thing,
              mode:'view',
              source:null
            })
          } else {
            alert(res.data.message);
          }
        }).catch((error) => {
          console.log(error);
          return error;
        });
      }
    }


    handleInputChange = (e) => {
      let updated_source = Object.assign({}, this.state.source);
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      updated_source[e.target.name] = value;
      console.log(updated_source)
      this.setState({source:updated_source});
    }

    // createProfile = (thing_review_source_id) => {
    //   console.log(thing_review_source_id);
    //   post(url + '/post/scrape', {thing_review_source_id:thing_review_source_id}, {
    //     auth: {
    //       username: this.props.userStore.token
    //     }
    //   }).then((res) => {
    //     if (res.data.errors === 0){
    //       let tmpThing = Object.assign({}, this.state.thing);
    //       let index = tmpThing.review_source_list.findIndex(x => x.id === thing_review_source_id);
    //       tmpThing.review_source_list[index] = res.data.review_source;
    //       this.setState({
    //         thing:tmpThing
    //       })
    //     }
    //     console.log(res.data);
    //   }).catch((error) => {
    //     console.log(error);
    //     return error;
    //   });
    // }
    //
    // profileStatus = (thing_review_source_id) => {
    //   post(url + '/post/scrape_status', {thing_review_source_id:thing_review_source_id}, {
    //     auth: {
    //       username: this.props.userStore.token
    //     }
    //   }).then((res) => {
    //     if (res.data.errors === 0){
    //       let tmpThing = Object.assign({}, this.state.thing);
    //       let index = tmpThing.review_source_list.findIndex(x => x.id === thing_review_source_id);
    //       tmpThing.review_source_list[index] = res.data.review_source;
    //       this.setState({
    //         thing:tmpThing
    //       })
    //     }
    //   }).catch((error) => {
    //     console.log(error);
    //     return error;
    //   });
    // }
    //
    // scrapeProfile = (thing_review_source_id) => {
    //   post(url + '/post/scrape_get', {thing_review_source_id:thing_review_source_id}, {
    //     auth: {
    //       username: this.props.userStore.token
    //     }
    //   }).then((res) => {
    //     if (res.data.errors === 0){
    //       let tmpThing = Object.assign({}, this.state.thing);
    //       let index = tmpThing.review_source_list.findIndex(x => x.id === thing_review_source_id);
    //       tmpThing.review_source_list[index] = res.data.review_source;
    //       this.setState({
    //         thing:tmpThing
    //       })
    //     }
    //   }).catch((error) => {
    //     console.log(error);
    //     return error;
    //   });
    // }
    //
    // analyzeReviews = (thing_review_source_id) => {
    //   post(url + '/post/analyze_reviews', {thing_review_source_id:thing_review_source_id}, {
    //     auth: {
    //       username: this.props.userStore.token
    //     }
    //   }).then((res) => {
    //     if (res.data.errors === 0){
    //       alert('Analysis Complete');
    //       let tmpThing = Object.assign({}, this.state.thing);
    //       let index = tmpThing.review_source_list.findIndex(x => x.id === thing_review_source_id);
    //       tmpThing.review_source_list[index] = res.data.review_source;
    //       this.setState({
    //         thing:tmpThing
    //       })
    //     }
    //   }).catch((error) => {
    //     console.log(error);
    //     return error;
    //   });
    // }


    render(){
      if (!this.state.thing) return <div>Nothing Loaded</div>;
      const {sys_review_source_list} = this.props;
      const {thing, mode, source} = this.state;
      return (
        <React.Fragment>
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="m-t-0"><i className="pg-icon">alert</i> {thing.name} - Review Sources</h3>
              <button className="btn btn-xs btn-success btn-light" onClick={this.addSource}>Add Source</button>
            </div>
            <div className="modal-body">
          {mode === 'edit' ?
            <React.Fragment>
              <div className="form-groups-attached">
                  <div className="row">
                    <div className="form-group form-group-default">
                        <label>URL/Query</label>
                        <input className="form-control" name="url" type="text" onChange={this.handleInputChange} value={source.url}/>
                    </div>
                  </div>
              </div>
              <div className="row">
                <div className="btn-group pull-right">
                  <button className="btn btn-secondary" onClick={()=>this.setState({mode:'view',source:null})}>Cancel</button>
                  <button className="btn btn-primary" onClick={this.saveSource}>Submit</button>
                </div>
              </div>
            </React.Fragment>
          : mode === 'add' ?
            <React.Fragment>
              <div className="modal-body">
                <div className="form-groups-attached">
                  <div className="row">
                    <div className="form-group form-group-default">
                      <label>Source</label>
                      <select className="form-control" name="sys_review_source_id" onChange={this.handleInputChange}>
                      {sys_review_source_list.map((source, index) =>
                        <option value={source.id}>{source.name}</option>
                      )}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group form-group-default">
                      <label>URL/Query</label>
                      <input className="form-control" name="url" type="text" onChange={this.handleInputChange} value={source.url}/>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="card-footer text-right">
                <button className="btn btn-secondary" onClick={()=>this.setState({mode:'view',source:null})}>Cancel</button>
                <button className="btn btn-primary" onClick={this.saveSource}>Submit</button>
              </footer>
            </React.Fragment>
            :
          <React.Fragment>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
            {thing.is_prism && thing.review_source_list.length > 0 ?
            thing.review_source_list.map((rs, index)=>
            <tr key={index}>
            <td><img style={{width:'100px'}} className="avatar avatar-sm" src={require('../img/' + rs.source_img)} alt="..."/></td>
            <td>{rs.source}</td>
            <td>
              {rs.url && rs.url !== '' ?
              <ul>
                <li><small>Last Job</small> {rs.last_complete_queue ? rs.last_complete_queue.complete_timestamp : 'No completed jobs'}</li>
                <li><small>Open Job</small> {rs.last_open_queue ? rs.last_open_queue.create_timestamp : 'No open jobs'}</li>
              </ul>
              :
              <ul>
                <li><small>NOT CONFIGURED</small></li>
              </ul>
              }
            </td>
            </tr>
            ):
            <tr><td>No Review Sources Found.  Add some!</td></tr>
            }
                </tbody>
              </table>
            </div>
          </div>
          </React.Fragment>
        }
            </div>
          </div>
        </div>
        </React.Fragment>
      );
    }
}

export default AdminThingDetail;

//
// <td>
//   <div className="btn-group">
//   {rs.url && rs.url !== '' ?
//     rs.last_open_queue ?
//       rs.last_open_queue.result_count > 0
//         ? <button className="btn btn-sm" onClick={()=>this.scrapeProfile(rs.id)}>Fetch Results</button>
//         : <button className="btn btn-sm" onClick={()=>this.profileStatus(rs.id)}>Check Status</button>
//       :
//     <button className="btn btn-sm" onClick={()=>this.createProfile(rs.id)}>Create Job</button>
//   : null}
//   {rs.unanalyzed_review_count !== 0 ?
//     <button className="btn btn-sm" onClick={()=>this.analyzeReviews(rs.id)}>Analyze</button>
//   : null}
//     <button className="btn btn-sm" onClick={()=>this.editSource(rs)}>Edit Source</button>
//   </div>
// </td>
