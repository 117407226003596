import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { Link, withRouter } from "react-router-dom";
import { inject } from 'mobx-react';
import { connectionGet, connectionPost, connectionPut } from '../utils/connection';
import AccountForm from './AccountForm';
import { auth } from '../firebase';
import {Spinner} from 'reactstrap';


@inject('userStore')
class AccountsNsa
 extends Component {
  constructor(props){
    super(props);
    this.state = {
      account_list:[],
      visible:false,
      loading:true,
      account_edit:{id:0, first_name:'', last_name:'', is_prism:0, is_admin:0, email:'', password:'', firebase_uid:'', sys_status_id:1}
    }
  }

  componentDidMount() {
    this.fetchAccounts();
  }

  fetchAccounts = () => {
    this.setState({
      loading:true
    },()=>{
      connectionGet('accounts', {user_id:this.props.userStore.id}, {username: this.props.userStore.token}
      ).then((res) => {
        this.setState({
          account_list:res.data.account_list,
          loading:false
        });
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  handleInputChange = (e) => {
    let updated_account = Object.assign({}, this.state.account_edit);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updated_account[e.target.name] = value;
    this.setState({account_edit:updated_account});
  }


  editAccount = (account) => {
    connectionGet('accounts/' + account.hash_id, {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
      this.setState({
        visible:!this.state.visible,
        account_edit:account
      });
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  addAccount = () => {
    this.setState({
      visible:!this.state.visible,
      account_edit:{id:0, first_name:'', last_name:'', is_prism:0, is_admin:0, email:'', password:'', firebase_uid:'', sys_status_id:1}
    });
  }

  modalBackdropClicked = () => {
    this.setState({
      visible:false,
      account_edit:{id:0, first_name:'', last_name:'', is_prism:0, is_admin:0, email:'', password:'', firebase_uid:'', sys_status_id:1}
    })
  }

  handleSubmit = () => {
    console.log(this.state.account_edit);
    if (this.state.account_edit.id === 0 && this.state.account_edit.email !== '' && this.state.account_edit.password !== ''){
      // create user in firebase and get uid
      auth.doCreateUserWithEmailAndPassword(this.state.account_edit.email, this.state.account_edit.password)
        .then((user) => {
          let firebase_uid = user.user.uid;
          connectionPost('accounts', {firebase_uid: firebase_uid, account:this.state.account_edit}, {username: this.props.userStore.token}
          ).then((res) => {
            if (res.data.errors === 0){

              // add to list or update item
              let tmp_account_list = Object.assign([], this.state.account_list);
              // modify the particular item
              tmp_account_list.push(res.data.account);
              this.setState({
                account_edit:{id:0, first_name:'', last_name:'', is_prism:0, is_admin:0, email:'', password:'', firebase_uid:'', sys_status_id:1},
                visible:false,
                account_list:tmp_account_list
              },()=>alert('Account Added'));
            }
          }).catch((error) => {
            console.log(error);
            return error;
          });
        })
        .catch(error => {
          if (error.message){
            alert(error.message);
          }
          return error
      });
    } else {
      // this is editing an existing user
      if (this.state.account_edit){
        let firebase_uid = this.state.account_edit.firebase_uid;
        connectionPut('accounts/' + this.state.account_edit.hash_id, {firebase_uid: firebase_uid, account:this.state.account_edit}, {username: this.props.userStore.token}
        ).then((res) => {
          console.log(res.data);
          if (res.data.errors === 0){
            // add to list or update item
            let tmp_account_list = Object.assign([], this.state.account_list);
            if (this.state.account_edit.id === 0){
              // add the returned item to the list of accounts
              tmp_account_list.push(res.data.account);
            } else {
              // modify the particular item
              let index = this.state.account_list.findIndex(x => x.id === res.data.account.id);
              tmp_account_list[index] = res.data.account;
            }
            this.setState({
              account_edit:{id:0, first_name:'', last_name:'', is_prism:0, is_admin:0, email:'', password:'', firebase_uid:'', sys_status_id:1},
              visible:false,
              account_list:tmp_account_list
            },()=>alert('Account Edited'))
          }
        }).catch((error) => {
          console.log(error);
          return error;
        });
      }

    }
  }

  render() {
    const {account_edit, visible, loading} = this.state;
    return (
      <React.Fragment>
        <Modal visible={visible} onClickBackdrop={this.modalBackdropClicked} dialogClassName="modal-lg">
          <div className="modal-header">
              <h4 className="modal-title">{account_edit.id === 0 ? 'Add Account' : 'Edit Account: ' + account_edit.first_name }</h4>
              <button type="button" className="close" onClick={this.modalBackdropClicked} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body">
            <AccountForm account={account_edit} handleInputChange={this.handleInputChange}/>
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={this.modalBackdropClicked}>
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>
                Save
              </button>
          </div>
        </Modal>
        <div className="card">
          <div className="card-header separator m-b-15">
            <h4 className="card-title">Account List</h4>
            <div className="card-controls">
              <ul>
                <li>
                  <div className="dropdown">
                    <a id="card-settings" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                      <i className="card-icon card-icon-settings "></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings">
                      <a href="#" className="dropdown-item" onClick={this.addAccount}>Add New</a>
                    </div>
                  </div>
                </li>
                <li><a href="#" className="card-refresh" data-toggle="refresh" onClick={this.fetchAccounts}><i className="card-icon card-icon-refresh"></i></a></li>
              </ul>
            </div>
          </div>
          <div className="card-body table-responsive-sm">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          :
            <table className="table table-condensed table-bordered">
              <thead>
                <tr>
                  <th width="20%">Name</th>
                  <th width="30%">Profiles</th>
                  <th width="10%">Active?</th>
                  <th width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
              {this.state.account_list ? this.state.account_list.map((account, index) => {
                if (account.sys_status_id === 1) {
                  return (
                    <tr key={index}>
                      <td>{account.account_name}<br/><small>{account.email}</small></td>
                      <td>
                        {account.profile_list ?
                          <ul className="">
                          {account.profile_list.map((profile, pindex) => {
                            return (<li key={pindex}><span className="title">{profile.name}</span></li>)
                          })}
                          </ul>
                        :null}
                      </td>
                      <td>
                        {account.sys_status_id === 1 ? <span className="badge badge-success">ACTIVE</span> : <span className="badge badge-danger">INACTIVE</span>}
                      </td>
                      <td>
                        <div className="btn-toolbar">
                          <div className="btn-group">
                            <button className="btn btn-sm" onClick={()=>this.editAccount(account)}>Edit</button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                }
              }
            ) : null}
              </tbody>
            </table>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountsNsa
;
