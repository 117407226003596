import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

@inject('userStore')
class AspectForm extends Component{
    constructor(props){
        super(props);
    }

    editorChangeHandler = (values) => {
      console.log('new values', values)
      this.props.handleAspectChange(values)
    }

    render(){
        if (!this.props.aspect) return <div className="alert alert-info">Loading Aspect Details...</div>;
        const {aspect} = this.props;
        return (
        <React.Fragment>
        <div className="form-type-combine">
          <div className="form-groups-attached">
              <div className="row">
                  <div className="form-group col-12">
                      <label>Aspect Features</label>
                      <Editor name="meta" value={aspect.aspect_json} onChange={this.editorChangeHandler}/>
                  </div>
              </div>
          </div>
        </div>
        </React.Fragment>
        );
    }
}

export default AspectForm;
