import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { connectionPost, connectionGet } from '../utils/connection';
import {Link} from "react-router-dom";
import {Spinner} from "reactstrap";
import moment from 'moment';
import Modal from 'react-bootstrap4-modal';
import ScraperComponentQuickview from './ScraperComponentQuickview';
import ReactJson from 'react-json-view'

@inject('userStore')
class ServiceJobs extends Component {
  constructor(props){
      super(props);
      this.state = {
        text:'',
        json:'',
        service_job_list:[],
        loading:false,
        quickviewVisible:false,
        source_list:[]
      }
  }

  componentDidMount(){
    // this.fetchServiceJobs();
    // this.fetchSources();
  }

  fetchServiceJobs = () => {
    this.setState({
      loading:true
    },()=>{
      connectionGet('service-jobs', {}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        this.setState({
          service_job_list:res.data.service_job_list,
          loading:false
        })
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  fetchSources = () => {
    this.setState({
      loading:true
    },()=>{
      connectionGet('thing-review-sources', {}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        this.setState({
          source_list:res.data.source_list,
          loading:false
        });
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  scrape = () => {
    connectionPost('scraper-util', {thing_review_source_id:6, action:'create_job'}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  scrapeStatus = () => {
    connectionPost('scraper-util', {thing_review_source_id:6, action:'check_job', job_id:176021277}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
    }).catch((error) => {
          console.log(error);
          return error;
    });
  }

  scrapeReviews = () => {
    connectionPost('scraper-util/scraper_get', {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
    }).catch((error) => {
          console.log(error);
          return error;
    });
  }

  processReview = () => {
    connectionPost('scraper-util/analyze_review', {id:this.state.text}, {username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        this.setState({
          json:res.data.results
        });
      }
      console.log(res.data);
    }).catch((error) => {
          console.log(error);
          return error;
    });
  }

  processReviews = () => {
    connectionPost('scraper-util/analyze_reviews2', {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
    }).catch((error) => {
          console.log(error);
          return error;
    });
  }

  analyzeSentiment = () => {
    connectionPost('scraper-util/analyze_reviews3', {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
    }).catch((error) => {
          console.log(error);
          return error;
    });
  }

  profileAnalysis = () => {
    connectionPost('scraper-util/profile_analyze', {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }


  profileAnalysis2 = () => {
    connectionPost('scraper-util/profile_analyze_2', {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  profileAnalysis3 = () => {
    connectionPost('scraper-util/analyze_review_bk', {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  profileAnalysis4 = () => {
    connectionPost('scraper-util/reanalyze_review_bk', {}, {username: this.props.userStore.token}
    ).then((res) => {
      console.log(res.data);
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  modalBackdropClicked = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible
    })
  }

  render(){
    const {loading, service_job_list, quickviewVisible, source_list} = this.state;
    return (
    <React.Fragment>
      <Modal visible={quickviewVisible} className="modal slide-right" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.modalBackdropClicked}>
        <ScraperComponentQuickview backgroundClickHandler={this.modalBackdropClicked} source_list={source_list}/>
      </Modal>
      <div className="card">
        <div className="card-header separator m-b-15">
          <h4 className="card-title">Service Job Log</h4>
          <div className="card-controls">
            <ul>
              <li>
                <div className="dropdown">
                  <a id="card-settings" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                    <i className="card-icon card-icon-settings "></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings">
                    <a href="#" className="dropdown-item" onClick={this.modalBackdropClicked}>Service Test Harness</a>
                  </div>
                </div>
              </li>
              <li><a href="#" className="card-refresh" data-toggle="refresh" onClick={this.fetchServiceJobs}><i className="card-icon card-icon-refresh"></i></a></li>
            </ul>
          </div>
        </div>
        <div className="card-body">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          :
          <table className="table table-condensed table-bordered">
            <thead>
              <tr>
                <th width="20%">Job</th>
                <th width="30%">Meta Data</th>
                <th width="30%">Result Message</th>
                <th width="20%">Date</th>
              </tr>
            </thead>
            <tbody>
            {service_job_list ? service_job_list.map((service_job, index) =>
              <tr key={index}>
                <td className="text-truncate fs-12 p-l-10">
                  <span className="d-block">{service_job.job_name}</span>
                  <span className="d-block">{parseInt(service_job.success) === 0 ? <span className="badge badge-danger">FAIL</span> : <span className="badge badge-success">SUCCESS</span>}</span>
                </td>
                <td className="p-l-10">
                {service_job.meta_data ?
                  <ReactJson src={service_job.meta_data} collapsed={true} />
                  : 'na'
                }
                </td>
                <td className="p-l-10">
                {parseInt(service_job.success) === 1 ?
                  <p>{service_job.success_msg}</p>
                  :
                  <p>{service_job.error_msg}</p>
                }
                </td>
                <td className="text-truncate fs-10 p-l-10">
                  {moment(service_job.create_timestamp).format('lll')}
                </td>
              </tr>
            ) : "Loading"}
            </tbody>
          </table>
          }
        </div>
      </div>
    </React.Fragment>
    );
  }
}

export default ServiceJobs;
