import React, { Component } from 'react';
import {Spinner} from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { connectionPost, connectionGet } from '../utils/connection';
import ReactJson from 'react-json-view'

@inject('userStore')
class ScraperComponentQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      thing_review_source_id:0,
      job_id:'',
      back_date:'',
      thing_id:'',
      loading:false,
      response:{message:'Select the Test you want to run'}
    }
  }

  handleInputChange = (e) => {
    let value = e.target.value;
    this.setState({[e.target.name]:value});
  }

  create_job = () => {
    if (this.state.thing_review_source_id !== 0){
      this.setState({
        loading:true
      },() => {
        connectionPost('scraper-util', {thing_review_source_id:this.state.thing_review_source_id, action:'create_job'}, {username: this.props.userStore.token}
        ).then((res) => {
          console.log(res.data);
          this.setState({
            loading:false,
            response:res.data.message
          })
        }).catch((error) => {
          console.log(error);
          return error;
        });
      })
    } else {
      alert('Select a Review Source')
    }
  }

  create_job_manual = () => {
    if (this.state.thing_review_source_id !== 0){
      this.setState({
        loading:true
      },() => {
        connectionPost('scraper-util', {thing_review_source_id:this.state.thing_review_source_id, action:'create_job_manual', back_date:this.state.back_date}, {username: this.props.userStore.token}
        ).then((res) => {
          console.log(res.data);
          this.setState({
            loading:false,
            response:res.data.message
          })
        }).catch((error) => {
          console.log(error);
          return error;
        });
      })
    } else {
      alert('Select a Review Source')
    }
  }


  job_status = () => {
    if (this.state.thing_review_source_id !== 0 && this.state.job_id !== ''){
      this.setState({
        loading:true
      },() => {
        connectionPost('scraper-util', {thing_review_source_id:this.state.thing_review_source_id, action:'check_job', job_id:this.state.job_id}, {username: this.props.userStore.token}
        ).then((res) => {
          console.log(res.data);
          this.setState({
            loading:false,
            response:res.data.message
          })
        }).catch((error) => {
          console.log(error);
          return error;
        });
      });
    } else {
      alert('Select a Review Source and Job ID')
    }
  }

  scrape_job = () => {
    if (this.state.job_id !== ''){
      this.setState({
        loading:true
      }, ()=> {
        connectionPost('scraper-util', {action:'scrape_job', job_id:this.state.job_id}, {username: this.props.userStore.token}
        ).then((res) => {
          console.log(res.data);
          this.setState({
            loading:false,
            response:res.data.message
          })
        }).catch((error) => {
          console.log(error);
          return error;
        });
      })
    } else {
      alert('Enter a Job ID')
    }
  }

  scrape_job_manual = () => {
    if (this.state.job_id !== ''){
      this.setState({
        loading:true
      }, ()=> {
        connectionPost('scraper-util', {action:'scrape_job_manual', job_id:this.state.job_id}, {username: this.props.userStore.token}
        ).then((res) => {
          console.log(res.data);
          this.setState({
            loading:false,
            response:res.data.message
          })
        }).catch((error) => {
          console.log(error);
          return error;
        });
      })
    } else {
      alert('Enter a Job ID')
    }
  }

  profile_analysis = () => {
    if (this.state.back_date !== ''){
      this.setState({
        loading:true
      }, ()=> {
        connectionPost('scraper-util', {action:'profile_analysis', back_date:this.state.back_date}, {username: this.props.userStore.token}
        ).then((res) => {
          console.log(res.data);
          this.setState({
            loading:false,
            response:res.data.message
          })
        }).catch((error) => {
          console.log(error);
          return error;
        });
      })
    } else {
      alert('Enter a Back Date')
    }
  }

  prism_reports = () => {
    this.setState({
      loading:true
    }, ()=> {
      connectionPost('scraper-util', { action: 'prism_emails', back_date: this.state.back_date }, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        this.setState({
          loading:false,
          response:res.data.message
        })
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  analysis_daily = () => {
    this.setState({
      loading:true
    }, ()=> {
      connectionPost('scraper-util', {action:'analysis_daily'}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        this.setState({
          loading:false,
          response:res.data.message
        })
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  import_qr = () => {
    this.setState({
      loading:true
    }, ()=> {
      connectionPost('scraper-util', {action:'import_qr', thing_id:this.state.thing_id}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        this.setState({
          loading:false,
          response:res.data.message
        })
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  analyze_qr = () => {
    this.setState({
      loading:true
    }, ()=> {
      connectionPost('scraper-util', {action:'analyze_qr'}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        this.setState({
          loading:false,
          response:res.data.message
        })
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  start_jobs = () => {
    this.setState({
      loading:true
    }, ()=> {
      connectionPost('scraper-util', {action:'start_jobs'}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        this.setState({
          loading:false,
          response:res.data.message
        })
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  check_rq = () => {
    this.setState({
      loading:true
    }, ()=> {
      connectionGet('scraper-util', {}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        this.setState({
          loading:false,
          response:res.data.message
        })
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }


  render(){
    const {job_id, thing_review_source_id, response, loading, back_date, thing_id} = this.state;
    const {backgroundClickHandler, source_list} = this.props;
    return (
      <React.Fragment>
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header clearfix text-left">
            <button aria-label="" type="button" className="close" onClick={backgroundClickHandler} aria-hidden="true"><i className="pg-icon">close</i>
            </button>
            <h5>Service Testing Harness</h5>
          </div>
          <div className="modal-body">
            {loading ?
              <h4><Spinner color="primary"/> Loading...</h4>
            :
              <ReactJson src={response} />
            }
            <form id="form-test-harness">
              <div className="form-group-attached">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group form-group-default">
                      <label>Review Source</label>
                      <select className="form-control" name="thing_review_source_id" onChange={this.handleInputChange} value={thing_review_source_id}>
                        <option></option>
                        {source_list.map((source, source_index)=>
                          <option value={source.id} key={source_index}>{source.name} :: {source.source_name}</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group form-group-default">
                      <label>Job ID</label>
                      <input type="text" name="job_id" value={job_id} className="form-control" onChange={this.handleInputChange}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group form-group-default">
                      <label>Profile Analysis Back Date</label>
                      <input type="text" name="back_date" value={back_date} className="form-control" onChange={this.handleInputChange}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group form-group-default">
                      <label>Import QR Thing ID</label>
                      <input type="text" name="thing_id" value={thing_id} className="form-control" onChange={this.handleInputChange}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-t-20 p-t-20">
                <div className="col-12">
                  <div className="btn-group pull-right">
                    <button className="btn btn-primary" type="button" onClick={this.create_job}><i className="material-icons fs-14">check</i> Create Job</button>
                    <button className="btn btn-primary" type="button" onClick={this.job_status}><i className="material-icons fs-14">check</i>  Check Job</button>
                    <button className="btn btn-primary" type="button" onClick={this.scrape_job}><i className="material-icons fs-14">check</i>  Scrape Job</button>
                  </div>
                </div>
              </div>
              <div className="row m-t-20 p-t-20">
                <div className="col-12">
                  <div className="btn-group pull-right">
                    <button className="btn btn-primary" type="button" onClick={this.analysis_daily}><i className="material-icons fs-14">check</i>  Analysis Daily</button>
                    <button className="btn btn-primary" type="button" onClick={this.profile_analysis}><i className="material-icons fs-14">check</i>  Profile Analysis</button>
                    <button className="btn btn-primary" type="button" onClick={this.prism_reports}><i className="material-icons fs-14">check</i>  Email Prism Reports</button>
                  </div>
                </div>
              </div>
              <div className="row m-t-20 p-t-20">
                <div className="col-12">
                  <div className="btn-group pull-right">
                    <button className="btn btn-primary" type="button" onClick={this.import_qr}><i className="material-icons fs-14">check</i>  Import QR</button>
                    <button className="btn btn-primary" type="button" onClick={this.analyze_qr}><i className="material-icons fs-14">check</i>  Analyze QR</button>
                    <button className="btn btn-primary" type="button" onClick={this.start_jobs}><i className="material-icons fs-14">check</i>  Start Jobs</button>
                  </div>
                </div>
              </div>
              <div className="row m-t-20 p-t-20">
                <div className="col-12">
                  <div className="btn-group pull-right">
                    <button className="btn btn-primary" type="button" onClick={this.create_job_manual}><i className="material-icons fs-14">check</i>  Create Job M</button>
                    <button className="btn btn-primary" type="button" onClick={this.scrape_job_manual}><i className="material-icons fs-14">check</i>  Scrape M</button>
                    <button className="btn btn-primary" type="button" onClick={this.check_rq}><i className="material-icons fs-14">check</i>  Test RQ</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default ScraperComponentQuickview;
