import React, { Component } from 'react';

class ProfileModalReviewSource extends Component {
  constructor(props){
    super(props);
    this.state = {
      thing:null,
      mode:'view',
      source:null
    }
  }

  componentDidMount(){
    if (this.props.mode === 'add'){
      this.setState({
        mode:'add',
        thing:this.props.thing,
        source:{id:0,url:'',sys_review_source_id:1,thing_id:this.props.thing.id}
      })
    } else {
      this.setState({
        thing:this.props.thing,
        source:this.props.source
      })
    }
  }

  addSource = () => {
    this.setState({
      mode:'add',
      source:{id:0,url:'',sys_review_source_id:1,thing_id:this.state.thing.id}
    })
  }

  editSource = (source) => {
    this.setState({
      mode:'edit',
      source:source
    })
  }

  submit = () => {
    console.log('submitting');
    this.props.saveSource(this.state.source);
  }

  handleInputChange = (e) => {
    let updated_source = Object.assign({}, this.state.source);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updated_source[e.target.name] = value;
    this.setState({source:updated_source});
  }

  render(){
    if (!this.state.thing) return <div>Nothing Loaded</div>;
    const {sys_review_source_list, mode} = this.props;
    const {thing, source} = this.state;
    return (
      <React.Fragment>
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="m-t-0">{thing.name} - Review Source</h5>
            </div>
            <div className="modal-body">
              <form role="form" id="form-rs">
                <div className="form-group-attached">
                {mode === 'edit' ?
                <React.Fragment>
                <div className="row">
                  <div className="col-1">
                    <div className="profile-img-wrapper m-t-5 inline">
                      <img width="35" height="35" data-src-retina={require('../img/' + source.sys_review_source_img)} data-src={require('../img/' + source.sys_review_source_img)} alt={source.name} src={require('../img/' + source.sys_review_source_img)}/>
                    </div>
                  </div>
                  <div className="col-11">
                    <div className="form-group form-group-default">
                      <label>URL/Query</label>
                      <input className="form-control" name="url" type="text" onChange={this.handleInputChange} value={source.url}/>
                    </div>
                  </div>
                </div>
                </React.Fragment>
                : mode === 'add' ?
                <React.Fragment>
                <div className="row">
                  <div className="form-group form-group-default">
                    <label>Source</label>
                    <select className="form-control" name="sys_review_source_id" onChange={this.handleInputChange}>
                    {sys_review_source_list.map((source, index) =>
                      <option value={source.id}>{source.name}</option>
                    )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group form-group-default">
                    <label>URL/Query</label>
                    <input className="form-control" name="url" type="text" onChange={this.handleInputChange} value={source.url}/>
                  </div>
                </div>
                </React.Fragment>
                : null}
                </div>
              </form>
              <div className="row m-t-20">
                <div className="col-6 offset-6">
                  <div className="btn-group pull-right">
                    <button aria-label="" type="button" className="btn btn-primary" onClick={this.submit}>Save Review Source</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProfileModalReviewSource;

//
// {mode === 'edit' ?
// <React.Fragment>
//   <div className="card-body">
//     <div className="form-type-combine">
//       <div className="form-groups-attached">
//           <div className="row">
//               <div className="form-group col-12">
//                   <label>URL/Query</label>
//                   <input className="form-control" name="url" type="text" onChange={this.handleInputChange} value={source.url}/>
//               </div>
//           </div>
//       </div>
//     </div>
//   </div>
//   <footer className="card-footer text-right">
//     <button className="btn btn-secondary" onClick={()=>this.setState({mode:'view',source:null})}>Cancel</button>
//     <button className="btn btn-primary" onClick={this.saveSource}>Submit</button>
//   </footer>
// </React.Fragment>
// : mode === 'add' ?
// <React.Fragment>
//   <div className="card-body">
//     <div className="form-type-combine">
//       <div className="form-groups-attached">
//           <div className="row">
//               <div className="form-group col-12">
//                   <label>Source</label>
//                   <select className="form-control" name="sys_review_source_id" onChange={this.handleInputChange}>
//                   {sys_review_source_list.map((source, index) =>
//                     <option value={source.id}>{source.name}</option>
//                   )}
//                   </select>
//               </div>
//           </div>
//           <div className="row">
//               <div className="form-group col-12">
//                   <label>URL/Query</label>
//                   <input className="form-control" name="url" type="text" onChange={this.handleInputChange} value={source.url}/>
//               </div>
//           </div>
//       </div>
//     </div>
//   </div>
//   <footer className="card-footer text-right">
//     <button className="btn btn-secondary" onClick={()=>this.setState({mode:'view',source:null})}>Cancel</button>
//     <button className="btn btn-primary" onClick={this.saveSource}>Submit</button>
//   </footer>
// </React.Fragment>
//   :
// <React.Fragment>
// <div className="card-body">
//   <div className="table-responsive">
//     <table className="table table-striped">
//       <tbody>
//   {thing.is_prism && thing.review_source_list.length > 0 ?
//   thing.review_source_list.map((rs, index)=>
//   <tr key={index}>
//   <td><img className="avatar avatar-sm" src={require('../img/' + rs.source_img)} alt="..."/></td>
//   <td>{rs.source}</td>
//   <td>
//     {rs.url && rs.url !== '' ?
//     <ul>
//       <li><small>Last Job</small> {rs.last_complete_queue ? rs.last_complete_queue.complete_timestamp : 'No completed jobs'}</li>
//       <li><small>Open Job</small> {rs.last_open_queue ? rs.last_open_queue.create_timestamp : 'No open jobs'}</li>
//     </ul>
//     :
//     <ul>
//       <li><small>NOT CONFIGURED</small></li>
//     </ul>
//     }
//   </td>
//   </tr>
//   ):
//   <tr><td>No Review Sources Found.  Add some!</td></tr>
//   }
//       </tbody>
//     </table>
//   </div>
// </div>
// </React.Fragment>
// }
