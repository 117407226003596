import React, { Component } from 'react';
import {inject} from 'mobx-react';
import { connectionGet } from '../utils/connection';
import AnalysisDetail from './AnalysisDetail';
import {Spinner} from 'reactstrap';

class AnalysisModal extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  render(){
    // if (!this.state.analysis) return <div>Loading...</div>;
    const {modalBackgroundClicked, analysis, profile} = this.props;
    return (
      <React.Fragment>
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="m-t-0"><i className="pg-icon">alert</i> Analysis Quickview</h3>
          </div>
          <div className="modal-body">
            {!profile ?
              <h4><Spinner color="primary"/> Loading...</h4>
            :
            <AnalysisDetail analysis={analysis} profile={profile}/>
            }
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default AnalysisModal;
