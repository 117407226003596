import React, {Component} from 'react';
import {inject} from 'mobx-react';

@inject('userStore')
class Logout extends Component{
  constructor(props){
    super(props);
    this.state = {}
  }

  componentDidMount(){
    this.props.userStore.logout();
  }

  render(){
    return (<h1>Logging off</h1>)
  }
}

export default Logout;
