import React, { Component } from 'react';
import {Radar} from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes';

class RadarChart extends Component {
  render() {
    const options = {
      scale: {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100
        }
      },
      plugins: {
       colorschemes: {
         scheme: 'brewer.SetThree12'
       }
      }
    };
    return (
    	<Radar data={this.props.data} width={900} height={650} options={options}/>
    );
  }
}

export default RadarChart;
