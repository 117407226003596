import React, { Component } from 'react';

class QuestionResults extends Component {
  render() {
    switch (this.props.result.sys_question_type_id) {
      case 1:
        if (this.props.result.result_numeric == 1){
            return (
              <div className="relative" style={{height:'40px'}}>
                <span className="top-left"><strong>{this.props.result.question_positive}</strong></span>
                <span className="top-right"><i className="pg-icon" style={{color:'#46be8a'}}>circle_fill</i></span>
              </div>
            );
        } else {
            return (
              <div className="relative" style={{height:'40px'}}>
                <span className="top-left"><strong>{this.props.result.question_positive}</strong></span>
                <span className="top-right"><i className="pg-icon" style={{color:'#FF0000'}}>circle_fill</i></span>
              </div>
            );
        }
        break;
      case 10:
        return (
          <div className="relative" style={{height:'40px'}}>
            <span className="top-left"><strong>{this.props.result.question_positive}</strong></span>
            <span className="top-right">
              <div className="small hint-text m-t-5">
              {Array(this.props.result.result_numeric + 1).fill().map((_, i) =>
                <small key={i} className="fs-30" style={{color:'#FFd945'}}><i className="pg-icon">star</i></small>
              )}
              </div>
            </span>
          </div>
        )
        break;
      case 2:
        return (
          <div className="relative" style={{minHeight:'50px'}}>
            <span className="top-left"><strong>{this.props.result.question_positive}</strong></span>
            <p className="hint-text scrollable relative p-t-20" style={{minHeight: '50px'}}>
              {this.props.result.result_text}
            </p>
          </div>
        )
        break;
      default:
        return (<React.Fragment/>);
    }
  }
}

export default QuestionResults;
