import React, { Component } from 'react';
import Score from '../common/Score';
import '../App.css';

class FormattedReview extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render(){
    const {review, vaderHandler} = this.props;
    return (
      <React.Fragment>
            <p className="aspect-p">
            {review.analyzed.analysis.map((sentence, index) => {
                var aspects = '';
                Object.entries(sentence.topic_classified).forEach(topic => {
                  if (topic[1].length > 0 && topic[1][1] > 1){
                    aspects = aspects.concat(" | " + topic[1][0]);
                  }
                });
                var style = '';
                var className = '';
                var sentimentScore = Math.round(sentence.sentiment[0] * 100)/100;
                if (sentimentScore > 0){
                  style = {backgroundColor: 'rgba(0, 255, 0, ' + sentimentScore + ')'}
                  className = 'text-title cursor-pointer aspect-flag aspect-sentiment-positive p-10';
                } else if (sentimentScore < 0) {
                  style = {backgroundColor: 'rgba(255, 0, 0, ' + Math.abs(sentimentScore) + ')'}
                  className = 'text-title cursor-pointer aspect-flag aspect-sentiment-negative p-10';
                } else {
                  style = {backgroundColor: 'rgba(255, 255, 0, 0.1)'}
                  className = 'text-title cursor-pointer aspect-flag aspect-sentiment-neutral p-10';
                }
                return (
                  <React.Fragment key={index}>
                    <div title={sentence.sentiment[0] + ' | ' + aspects} className={className} style={style}>
                      {sentence.sentence}
                    </div><br/>
                  </React.Fragment>);
            })}
            </p>
      </React.Fragment>
    );
  }
}

class AnalysisFlagRow extends Component {
  constructor(props){
    super(props);
    this.state = {
      sentence_list:[]
    }
  }

  median = (values) => {
    if(values.length === 0) return 0;

    values.sort(function(a,b){
      return a-b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2)
      return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }

  render() {
    const {quickviewHandler, editHandler, selectedId, readonly, flag, vaderHandler} = this.props;
    let grade = 'C';
    let median = 0;
    let review = flag.review;
    if (review.analyzed && review.analyzed.analysis.length > 0) {
      if (Array.isArray(review.analyzed.analysis)){
        let value_list = [];
        review.analyzed.analysis.map((sentence) => {
          if (Math.abs(sentence.sentiment[0]) > .1){
            value_list.push(sentence.sentiment[0])
          }
        });
        median = this.median(value_list);
        median = parseInt((median + 1) * 50);
      } else {
        median = review.sentiment_median;
      }
    } else {
      median = review.sentiment_median;
    }
    switch (true) {
      case median < 50:
        grade = 'F';
        break;
      case median < 60:
        grade = 'D';
        break;
      case median < 70:
        grade = 'C';
        break;
      case median < 80:
        grade = 'B';
        break;
      case median < 90:
        grade = 'A';
        break;
      case median <= 100:
        grade = 'A+';
        break;
      default:
    }

    return (
      <div className={selectedId && review.id === selectedId ? "bg-pale-yellow card card-bordered cursor-pointer" : "card card-bordered cursor-pointer"} onClick={()=>quickviewHandler(review)}>
        <div className="media bb-1 border-fade">
          <div className="media-body">
            <img className="avatar avatar-sm pull-left mr-20" src={require('../img/' + review.sys_review_source_img)} alt="source"/>
            <div className="pull-left">
            {Array(review.rating_value).fill().map((_, i) =>
              <small key={i} className="text-warning fs-30"><i className="fa fa-star pr-1"></i></small>
            )}
            </div>
          </div>
          <div className="pull-left">
            <time className="mr-20">{review.review_date}</time>
            {!readonly ?
            <div className="pull-right">
              <a className="btn btn-xs btn-w-xs btn-outline btn-primary" href={review.url} target="_blank">View</a>
            </div>
            : null}
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-20">
            <div className="col-12">
              <h3>{review.location_name}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <h6>Flag Comment</h6>
              <p>{flag.comment}</p>
            </div>
            <div className="col-8">
              {review.analyzed && Array.isArray(review.analyzed.analysis) ?
                <React.Fragment>
                  <FormattedReview review={review} vaderHandler={vaderHandler} quickviewHandler={quickviewHandler}/>
                </React.Fragment>
              : <p className="small">No Review was left</p>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AnalysisFlagRow;

// <div className="media flex-column b-1 p-0 mx-auto">
//   <span className="m-auto p-2">
//     <span className="fs-50" style={{fontFamily:'Aleo, serif'}}>{grade}</span>
//   </span>
//   <div className="flexbox bg-pale-secondary bt-1 border-light px-12 py-10 w-100">
//     <span className="flex-grow">{median}</span>
//     <a className="media-action hover-primary" href="#" onClick={editHandler} data-provide="tooltip" title="" data-original-title="Edit"><i className="ti-pencil"></i></a>
//     <a className="media-action hover-danger" href="#" data-provide="tooltip" title="" data-original-title="Delete"><i className="ti-close"></i></a>
//   </div>
// </div>
