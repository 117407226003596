import React, { Component } from 'react';
import {inject} from 'mobx-react';
import {connectionGet, connectionPut, connectionPost} from '../utils/connection';
import ProfileInfo from './ProfileInfo';
import ProfileAccess from './ProfileAccess';
import ProfileLocations from './ProfileLocations';
import {ToastContainer, toast} from 'react-toastify';
import {Spinner} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class ProfileSettings extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      item:null,
      aspect_custom_list:[],
      sys_schedule_list:[],
      subComponentShown:'info',
      account_list:[],
      available_account_list:[],
      sys_role_list:[],
      location_list:[],
      sys_review_source_list:[]
    }
  }

  componentDidMount(){
    this.fetchProfile();
  }

  componentWillReceiveProps(nextProps){
    this.fetchProfile();
  }

  fetchProfile = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('v2/profiles/' + this.props.match.params.hashId, {settings:1}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            item:res.data.profile,
            aspect_custom_list:res.data.aspect_custom_list,
            sys_schedule_list:res.data.sys_schedule_list,
            available_account_list:res.data.account_list,
            account_list:res.data.profile.account_list,
            sys_role_list:res.data.sys_role_list,
            location_list:res.data.location_list,
            sys_review_source_list:res.data.sys_review_source_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  selectSubComponent = (subComponent) => {
    this.setState({
      subComponentShown:subComponent
    })
  }

  saveItem = () => {
    return false;
  }

  saveUser = (user) => {
    if (user.id !== 0){
      connectionPut('v2/profiles/'+ this.state.item.hash_id +'/account', {user:user}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          let profile = Object.assign({}, this.state.item);
          let index = profile.account_list.findIndex(f => f.id === parseInt(res.data.item.id));
          if (index > -1){
            profile.account_list[index] = res.data.item;
          }
          this.setState({item: profile});
          toast.success(res.data.message, toastParams);
        } else {
          toast.error(res.data.message, toastParams);
        }
      }).catch((error) => {
        return error;
      });
    } else {
      connectionPost('v2/profiles/'+ this.state.item.hash_id +'/account', {user:user}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          let profile = Object.assign({}, this.state.item);
          let acc_list = profile.account_list.slice();
          acc_list.push(res.data.item);
          profile.account_list = acc_list;
          this.setState({item: profile});
          toast.success(res.data.message, toastParams);
        } else {
          toast.error(res.data.message, toastParams);
        }
      }).catch((error) => {
        return error;
      });
    }
  }

  render(){
    const {loading, item, subComponentShown, sys_schedule_list, aspect_custom_list, account_list, sys_role_list, location_list, sys_review_source_list, available_account_list} = this.state;
    return (
      <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="no-padding container-fixed-lg bg-white">
        <div className="container">
          <div className="card col-12">
          {loading ?
            <div className="card-header">
              <h3><Spinner color="primary"/> Loading Settings...</h3>
            </div>
          :
          <React.Fragment>
            <div className="card-header">
              <h3><i className="pg-icon">settings</i> {item.name} - Settings</h3>
            </div>
            <div className="card-body">
              <div className="card card-transparent flex-row">
                <ul className="nav nav-tabs nav-tabs-simple nav-tabs-left bg-white">
                  <li className="nav-item">
                    <a href="#" className={subComponentShown === 'info' ? "active" : null} onClick={()=>this.selectSubComponent('info')} data-toggle="tab" data-target="#information"><i className="pg-icon">alert</i> Information</a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className={subComponentShown === 'access' ? "active" : null}  onClick={()=>this.selectSubComponent('access')} data-toggle="tab" data-target="#access"><i className="pg-icon">users</i> Access</a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className={subComponentShown === 'locations' ? "active" : null}  onClick={()=>this.selectSubComponent('locations')} data-toggle="tab" data-target="#locations"><i className="pg-icon">table</i> Locations</a>
                  </li>
                </ul>
                <div className="tab-content bg-white flex-grow-1 full-height">
                  <div className={subComponentShown === 'info' ? "tab-pane active full-height" : "tab-pane"} id="info">
                    <PerfectScrollbar>
                      <ProfileInfo handleInputChange={this.handleInputChange} item={item} submitHandler={this.saveItem} aspect_custom_list={aspect_custom_list} sys_schedule_list={sys_schedule_list}/>
                    </PerfectScrollbar>
                  </div>
                  <div className={subComponentShown === 'access' ? "tab-pane active" : "tab-pane"} id="access">
                    <ProfileAccess item={item} available_account_list={available_account_list} account_list={account_list} sys_role_list={sys_role_list} addUser={this.saveUser}/>
                  </div>
                  <div className={subComponentShown === 'locations' ? "tab-pane active" : "tab-pane"} id="locations">
                    <ProfileLocations item={item} location_list={location_list} sys_review_source_list={sys_review_source_list}/>
                  </div>
                </div>
              </div>
            </div>
            </React.Fragment>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
    )
  }
}

export default ProfileSettings;
