import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('userStore')
class Test extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="no-padding container-fixed-lg bg-white">
        <div className="container">
          <div className="col-12">
            <h3>Welcome Back.  Please select an option above</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Test;
