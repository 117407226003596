import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { connectionGet, connectionPost } from '../utils/connection';
import ScraperComponent from './ScraperComponent';
import Things from './Things';
import Accounts from './Accounts';
import ReviewSources from './ReviewSources';
import ReviewFlags from './ReviewFlags';
import ServiceJobs from './ServiceJobs';

@inject('userStore')
class SuperAdmin extends Component {
    constructor(props){
        super(props);
        this.state = {
          selectedComponent:null,
          componentName:'ServiceJobs'
        }
    }

    componentDidMount() {
      this.setState({
        selectedComponent: <ServiceJobs/>
      })
    }

    send_email = () => {
      connectionGet('send_email', {}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res);
      }).catch((error) => {
        console.log(error);
        return error;
      });
    }

    selectComponent = (componentName) => {
      switch (componentName) {
        case 'Things':
          this.setState({
            selectedComponent: <Things/>,
            componentName:componentName
          })
          break;
        case 'Accounts':
          this.setState({
            selectedComponent: <Accounts/>,
            componentName:componentName
          })
          break;
        case 'Scraper':
          this.setState({
            selectedComponent: <ScraperComponent/>,
            componentName:componentName
          })
          break;
        case 'Sources':
          this.setState({
            selectedComponent: <ReviewSources/>,
            componentName:componentName
          })
          break;
        case 'Flags':
          this.setState({
            selectedComponent: <ReviewFlags/>,
            componentName:componentName
          })
          break;
        case 'ServiceJobs':
          this.setState({
            selectedComponent: <ServiceJobs/>,
            componentName:componentName
          })
          break;

        default:

      }
    }


    render() {
      const {selectedComponent, componentName} = this.state;
      return (
      <React.Fragment>
      <div className="no-padding container-fixed-lg bg-white" style={{minHeight:"600px"}}>
        <div className="container">
          <div className="card card-transparent">
            <div className="card-header p-l-0">
              <div className="card-title">
                <h4>Super Administration</h4>
                <button className="btn btn-primary" onClick={this.send_email}>Send Email</button>
              </div>
            </div>
            <div className="card-body p-l-0 p-r-0">
              <div className="row">
                <div className="col-lg-3 col-sm-12 b-r b-grey">
                  <div className="btn-group-vertical d-block">
                    <button className="btn btn-outline-default" onClick={()=>this.selectComponent('ServiceJobs')}>Service Status</button>
                    <button className="btn btn-outline-default" onClick={()=>this.selectComponent('Scraper')}>Review Queue</button>
                    <button className="btn btn-outline-default" onClick={()=>this.selectComponent('Things')}>Things & Locations</button>
                    <button className="btn btn-outline-default" onClick={()=>this.selectComponent('Accounts')}>Accounts</button>
                    <button className="btn btn-outline-default" onClick={()=>this.selectComponent('Sources')}>Review Sources</button>
                  </div>
                </div>
                <div className="col-lg-9 col-sm-12">
                  {selectedComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
      );
    }
}

export default SuperAdmin;
