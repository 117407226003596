import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SketchPicker } from 'react-color';

@inject('userStore')
class ThingForm extends Component{
    constructor(props){
        super(props);
    }
    
    handleChangeComplete = (color) => {
       // this.setState({ background: color.hex },()=>{
       //     this.formApi.setValue('graph_color', color.hex);
       //     console.log(this.formApi.getState().values);
       // });
    };

    render(){
        if (!this.props.thing) return <div className="alert alert-info">Loading Thing Details...</div>;
        const {thing, handleInputChange, question_set_list, incentive_list, account_list} = this.props;
        return (
        <React.Fragment>
        <div className="form-type-combine">
          <div className="form-groups-attached">
              <div className="row">
                  <div className="form-group col-4">
                      <label>Name</label>
                      <input className="form-control" name="name" type="text" onChange={handleInputChange} value={thing.name}/>
                  </div>
                  <div className="form-group col-4">
                      <label>Vanity</label>
                      <input className="form-control" name="unique_name" type="text" onChange={handleInputChange} value={thing.unique_name}/>
                  </div>
                  <div className="form-group col-4">
                      <label>Account Owner</label>
                      <select className="form-control" name="owner_account_id" onChange={handleInputChange} value={thing.owner_account_id}>
                      {account_list.map((account, a_index)=>(
                        <option value={account.id} key={a_index}>{account.account_name}</option>
                      ))}
                      </select>
                  </div>
              </div>
              <div className="row">
                  <div className="form-group col-8">
                    <label className="">Thing Type</label>
                    <select className="form-control" name="sys_thing_type_id" onChange={handleInputChange} value={thing.sys_thing_type_id}>
                      <option value="1">Location</option>
                      <option value="2">Object</option>
                    </select>
                  </div>
                  <div className="form-group col-4">
                    <label className="">Days Between</label>
                    <input className="form-control" name="days_between" type="text" onChange={handleInputChange} value={thing.days_between}/>
                  </div>
              </div>
              {thing.question_set_list ?
              <div className="row">
                  <div className="form-group col-6">
                    <label className="">Existing Question Set</label>
                    <select className="form-control" name="question_set_id" onChange={handleInputChange} value={thing.question_set_id}>
                    {question_set_list.map((question, q_index)=>(
                      <option value={question.id} key={q_index}>{question.name}</option>
                    ))}
                    </select>
                  </div>
                  <div className="form-group col-6">
                    <label className="">Chart Color</label>
                    <SketchPicker color={thing.chart_color} onChangeComplete={ this.handleChangeComplete }/>
                    <input className="form-control" name="chart_color" value={thing.chart_color} onChange={handleInputChange} type="hidden"/>
                  </div>
              </div>
              : null}
          </div>
        </div>
        </React.Fragment>
        );
    }
}

export default ThingForm;
