import React, { Component } from 'react';
import AppRoutes from './AppRoutes';
import Gravatar from 'react-gravatar';
import Settings from './Settings';
import Modal from 'react-bootstrap4-modal';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';

@inject('userStore')
@observer
class BaseLayout extends Component {
    constructor(props){
      super(props);
    }

    backgroundClick = () => {
      this.props.userStore.modalVisible = false;
    }

    openSettings = () => {
      this.props.userStore.modalVisible = true;
    }

    render() {
        return (
            <React.Fragment>
            <Modal visible={this.props.userStore.modalVisible} className="modal slide-up" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.backgroundClick}>
              <Settings onClickBackdrop={this.backgroundClick}/>
            </Modal>
            <div className="header">
              <div className="container">
                <div className="header-inner header-md-height">
                  <a href="#" className="btn-link toggle-sidebar d-lg-none header-icon sm-p-l-0 btn-icon-link" data-toggle="horizontal-menu">
                    <i className="pg-icon">menu</i>
                  </a>
                  <div className="">
                  </div>
                  <div className="d-flex align-items-center">

                    <div className="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none">
                      <span className="semi-bold">{this.props.userStore.name}</span>
                    </div>
                    <div className="dropdown pull-right d-lg-block">
                      <button className="profile-dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="profile dropdown">
                        <span className="thumbnail-wrapper d32 circular inline">
                          <Gravatar email={this.props.userStore.email} size={32} rating="pg" default="retro" className="avatar" />
            						</span>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right profile-dropdown" role="menu">
                        <a href="#" className="dropdown-item"><span>Signed in as <br /><b>{this.props.userStore.name}</b></span></a>
                        <div className="dropdown-divider"></div>
                        <a href="#" className="dropdown-item" onClick={this.openSettings}>Your Profile</a>
                        <div className="dropdown-divider"></div>
                        <a href="mailto:andrew@wirl.io" className="dropdown-item">Help</a>
                        <a href="/" className="dropdown-item" onClick={()=>this.props.userStore.logout()}>Logout</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header-inner justify-content-start header-lg-height title-bar">
                  <div className="brand inline align-self-end">
                     <img src={require('../img/logo.revu.sm.png')} alt="logo" data-src={require('../img/logo.revu.sm.png')} height="30" style={{position:'relative',top:'9px'}}/>
                  </div>
                  <h2 className="page-title align-self-end"></h2>
                </div>
                <div className="menu-bar header-sm-height" data-pages-init='horizontal-menu' data-hide-extra-li="4">
                  <a href="#" className="btn-link header-icon toggle-sidebar d-lg-none" data-toggle="horizontal-menu">
                    <i className="pg-icon">close</i>
                  </a>
                  <ul>
                    <li className=" active">
                      <a href="/prism/profiles">Prism</a>
                    </li>
                    <li className=" active">
                      <a href="/qr">QR Reviews</a>
                    </li>
                    {this.props.userStore.is_admin === 1 ?
                      <li className=" active">
                        <a href="/admin">Administration</a>
                      </li>
                    : null}
                    { this.props.userStore.is_admin === 1 && this.props.userStore.id === 1 ?
                      <li className=" active">
                        <a href="/sa">Super Administration</a>
                      </li>
                    : null}
                  </ul>
                </div>
              </div>
            </div>
            <div className="page-container">
            <AppRoutes/>
            </div>
            </React.Fragment>
        );
    }
}

export default BaseLayout;
