import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import InputMask from 'react-input-mask';
import ProfileModalReviewSource from './ProfileModalReviewSource';
import Modal from 'react-bootstrap4-modal';
import {ToastContainer, toast} from 'react-toastify';
import { connectionPost, connectionPut } from '../utils/connection';
import { inject, observer } from 'mobx-react';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class ProfileLocations extends Component{
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      modalVisible:false,
      source:null,
      thing:null,
      location_list:[]
    }
  }

  componentDidMount(){
    this.setState({
      location_list:this.props.location_list
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      location_list:nextProps.location_list
    })
  }

  openModal = (source, thing) => {
    this.setState({
      source:source,
      modalVisible:true,
      mode:source ? 'edit' : 'add',
      thing:thing
    })
  }

  closeModal = () => {
    this.setState({
      source:null,
      modalVisible:false,
      mode:'',
      thing:null
    })
  }

  saveSource = (source) => {
    if (this.state.mode === 'add'){
      connectionPost('things/' + this.state.thing.hash_id + '/review_source', {source:source}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          let tmp_location_list = Object.assign([], this.state.location_list);
          let index = tmp_location_list.findIndex(x => x.id === this.state.thing.id);
          if (index > -1){
            tmp_location_list[index].review_source_list = res.data.review_source_list;
          }
          this.setState({
            location_list:[]
          },()=>this.setState({
            location_list:tmp_location_list
          }))
        } else {
          toast.error(res.data.message, toastParams);
        }
      }).catch((error) => {
        console.log(error);
        return error;
      });
    } else {
      connectionPut('things/' + this.state.thing.hash_id + '/review_source', {source:source}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
        } else {
          toast.error(res.data.message, toastParams);
        }
      }).catch((error) => {
        console.log(error);
        return error;
      });
    }
  }

  render(){
    if (!this.props.item) return <div>Loading</div>;
    const {backgroundClickHandler, item, sys_review_source_list} = this.props;
    const {source, mode, modalVisible, thing, location_list} = this.state;
    const columns = [{
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.name}
          </div>
        );
      }
    }, {
      dataField: 'dummy',
      isDummyField: true,
      text: 'Review Sources',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <ul>
          {row.review_source_list.map((source, source_index)=>
            <li key={source_index}>{source.name} <i className="pg-icon btn-icon-link" onClick={()=>this.openModal(source, row)}>pencil</i></li>
          )}
          </ul>
        )
      }
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <button className="btn btn-primary" onClick={()=>this.openModal(null, row)}>Add Review Source</button>
        );
      }
    }];
    return (
      <React.Fragment>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
          pauseOnHover={false}
        />
        <Modal visible={modalVisible} className="modal slide-right" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.closeModal}>
          {modalVisible ?
            <ProfileModalReviewSource source={source} thing={thing} saveSource={this.saveSource} closeHandler={this.closeModal} mode={mode} sys_review_source_list={sys_review_source_list}/>
          : <div></div>
          }
        </Modal>
        <h3 className="m-t-0"><i className="pg-icon">users</i> Locations</h3>
        <div className="table-responsive">
          <BootstrapTable keyField='id' classes='table-condensed table table-striped table-hover table-bordered' data={location_list} columns={columns} striped hover headerClasses="thead-light"/>
        </div>
    </React.Fragment>
    )
  }
}

export default ProfileLocations;
