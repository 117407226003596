import React, { Component } from 'react';
import InputRange from 'react-input-range';
import { inject } from 'mobx-react';
import { connectionPut } from '../utils/connection';
import "react-input-range/lib/css/index.css";

@inject('userStore')
class ProfileReviewsAdjustQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      review:null
    }
  }

  componentDidMount(){
    this.setState({
      review:this.props.review
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      review:nextProps.review
    })
  }

  updateReview = () => {
    connectionPut('profiles/reviews/' + this.state.review.id, {review:this.state.review}, {username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        this.setState({
          review:res.data.review
        }, ()=>this.props.closeHandler(res.data.review))
      }
    }).catch((error) => {
      return error;
    });
  }

  sentimentEdit = (value, analysis) => {
    if (this.state.review){
      var review_edited = Object.assign({}, this.state.review);
      if (review_edited.analyzed && review_edited.analyzed.analysis){
        let index = review_edited.analyzed.analysis.findIndex(x => x === analysis);
        // analysis.sentiment[2] = value;
        analysis.sentiment[0] = value;
        review_edited.analyzed.analysis[index] = analysis;
        this.setState({
          review:review_edited
        }, ()=>console.log(this.state.review))
      }
    }
  }

  render(){
    const {closeHandler, review} = this.props;
    return (
    <React.Fragment>
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header clearfix text-left">
            <button aria-label="" type="button" className="close" onClick={closeHandler} aria-hidden="true"><i className="pg-icon">close</i>
            </button>
            <h5>Adjust Review Scoring</h5>
          </div>
          <div className="modal-body">
            {review && review.analyzed ?
              review.analyzed.analysis.map((analysis, index)=>
                <div key={index} className="card card-bordered">
                  <div className="card-body">
                    <p className="text-hint mb-10">
                    {analysis.sentence}
                    </p>
                    <div className="m-t-10 m-b-20">
                    <label>Sentiment Score</label>
                    <InputRange
                      step={.01}
                      maxValue={1}
                      minValue={-1}
                      value={analysis.sentiment[0]}
                      onChange={(value) => this.sentimentEdit(value, analysis)} />
                    </div>
                  </div>
                </div>
              )
            : 'Loading'}
            <div className="btn-group pull-right">
              <button type="button" className="btn btn-secondary" onClick={closeHandler}>
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={this.updateReview}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    );
  }
}

export default ProfileReviewsAdjustQuickview;

// <label>Aspects</label>
// <input type="text" value={analysis.topic_classified ? analysis.topic_classified.map((topic, index)=>topic[0] + ',') : null} data-provide="tagsinput" className="mt-20"/>
