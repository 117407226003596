import React, { Component } from 'react';
import { connectionGet, connectionPost } from '../utils/connection';
import { inject } from 'mobx-react';
import AnalysisRow from './AnalysisRow';
import Score from '../common/Score';
import Modal from 'react-bootstrap4-modal';
import moment from 'moment';

class Filter{
  location_list = [];
  start_date = new Date();
  end_date = new Date();
  aspect_custom_id = 0;
  date_range = [7, 'Last Week'];
}

@inject('userStore')
class ProfileAnalysisTable extends Component {
  constructor(props){
    super(props);
    this.state = {
      analysis:null,
      showOverall:false,
      showLetter:false,
      showDifferential:false,
      loading:false,
      review_list:[],
      visible:false
    }
  }

  // componentDidMount(){
  //   this.selectAnalysis(this.props.analysis_hash_id, this.props.profile.hash_id);
  // }
  //
  // componentWillReceiveProps(nextProps){
  //   this.selectAnalysis(nextProps.analysis_hash_id, nextProps.profile.hash_id);
  // }

  // selectAnalysis = (analysis_hash_id, profile_hash_id) => {
  //   // fetch the analysis by hash_id
  //   if (analysis_hash_id){
  //     this.setState({loading:true});
  //     connectionGet('profiles/' + profile_hash_id + '/analysis/' + analysis_hash_id, {}, {username: this.props.userStore.token}
  //     ).then((res) => {
  //       if (res.data.errors === 0){
  //         this.setState({
  //           analysis:res.data.profile_analysis,
  //           loading:false
  //         });
  //       } else {
  //         this.setState({
  //           loading:false
  //         });
  //         alert(res.data.message);
  //       }
  //     }).catch((error) => {
  //       return error;
  //     });
  //   }
  // }
  //
  modalBackdropClicked = () => {
    this.setState({
      visible:false,
      review_list:[]
    })
  }

  render(){
    const {getResults, profile, loadReviews, analysis} = this.props;
    const {showOverall, showDifferential, loading, qvTitle, showLetter, review_list, visible} = this.state;
    return (
      <React.Fragment>
          {analysis ?
            <React.Fragment>
            <div className="custom-control custom-checkbox" onClick={()=>this.setState({showOverall:!this.state.showOverall})}>
              <input type="checkbox" className="custom-control-input" id="showOverall" checked={this.state.showOverall} readOnly/>
              <label className="custom-control-label" htmlFor="showOVerall">Include Overall Averages</label>
            </div>
            <div className="custom-control custom-checkbox" onClick={()=>this.setState({showLetter:!this.state.showLetter})}>
              <input type="checkbox" className="custom-control-input" id="showLetter" checked={this.state.showLetter} readOnly/>
              <label className="custom-control-label" htmlFor="showLEtter">Show Letter Grade</label>
            </div>
            <div className="custom-control custom-checkbox" onClick={()=>this.setState({showDifferential:!this.state.showDifferential})}>
              <input type="checkbox" className="custom-control-input" id="showDifferential" checked={this.state.showDifferential} readOnly/>
              <label className="custom-control-label" htmlFor="showDIfferential">Show Differential (beta)</label>
            </div>
            <div className="table-responsive">
            {analysis.sentiment_json ?
              <table className="table table-condensed">
                <thead>
                  <tr>
                    <th className="text-center"></th>
                  {showOverall ? <th className="text-center text-truncate">Overall</th> : null}
                  {Object.keys(analysis.sentiment_json.locations).map((key, index)=>
                    <th key={index} className="text-center text-truncate">{profile.thing_dict[key]}</th>
                  )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-truncate">Overall</td>
                    {showOverall ?
                    <td className="text-center">
                      <Score asLetter={showLetter} score={analysis.sentiment_json.score} size={60} isConverted scoreType={'test'} count={analysis.sentiment_json.count}/>
                    </td>
                    : null}
                    {Object.keys(analysis.sentiment_json.locations).map((key, index)=>
                      <td key={index} className="text-center cursor-pointer" onClick={() => loadReviews(key, analysis)}>
                        <Score asLetter={showLetter} score={analysis.sentiment_json.locations[key].score_counter[key]/analysis.sentiment_json.locations[key].score_count[key]} scoreComp={showDifferential ? analysis.sentiment_json.score : null} size={60} scoreType={'test'} isConverted count={analysis.sentiment_json.locations[key].score_count[key]}/>
                      </td>
                    )}
                  </tr>
                  {Object.keys(analysis.sentiment_json.aspects).map((key, index)=>{
                    let score = 0;
                    let count = 0;
                    score = analysis.sentiment_json.aspects[key][0];
                    count = analysis.sentiment_json.aspects[key][1];
                    return (
                      <tr key={index}>
                        <td className="text-truncate"><span className="text-truncate">{key}</span></td>
                        {showOverall ?
                        <td className="text-center">
                          <Score asLetter={showLetter} score={score} size={50} isConverted={true} scoreType={'test'} count={count}/>
                        </td>
                        : null}
                        {Object.keys(analysis.sentiment_json.locations).map((loc_key, loc_index)=>{
                          let scoreLoc = 0;
                          let count = 0;
                          let link = false;
                          let id_list = [];
                          if (analysis.sentiment_json.locations[loc_key].aspect_counter[key]){
                            scoreLoc = analysis.sentiment_json.locations[loc_key].aspect_counter[key][0];
                          }
                          if (analysis.sentiment_json.locations[loc_key].aspect_count[key]){
                            count = analysis.sentiment_json.locations[loc_key].aspect_count[key];
                          }
                          return (
                          <td key={loc_index} className={count > 0 ? "text-center cursor-pointer" : "text-center"} onClick={() => getResults(loc_key, profile.thing_dict[loc_key], key)}>
                            <Score asLetter={showLetter} score={scoreLoc} scoreComp={showDifferential ? score : null} size={50} isConverted={true} scoreType={'test'} count={count}/>
                          </td>);
                        })}
                      </tr>);
                  })}
                </tbody>
              </table>
            : null}
            </div>
            </React.Fragment>
            : <div className="alert alert-info">Select an Analysis record to see results</div>
          }
      </React.Fragment>
    );
  }
}

export default ProfileAnalysisTable;
