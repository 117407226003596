import React, { Component } from 'react';
import {Route} from "react-router-dom";
import Test from '../Test';
import ProfileList from '../prism/ProfileList';
import ProfileDetail from '../prism/ProfileDetail';
import ProfileReviews from '../prism/ProfileReviews';
import ProfileSettings from '../prism/ProfileSettings';
import SuperAdmin from '../admin/SuperAdmin';
import Admin2 from '../admin/Admin2';
import QRReviews from '../revu/QRReviews';
import Logout from './Logout';
// import LocationWaitlist from '../business/LocationWaitlist';
// import Reservations from '../business/Reservations';
// import Users from '../business/Users';
// import Checkins from '../reporting/Checkins';
// import FeedbackReports from '../reporting/Feedback';
// import Waitlist from '../waitlist/Waitlist';
// import KonvaTest from './konva';
// import KonvaTest2 from './konva_2';
// import Questionnaires from '../feedback/Questionnaires';
// import Incentives from '../incentives/Incentives';
// import LocationSettings from '../business/LocationSettings';

// import Batches from './Batches';
// import AttendanceLog from './AttendanceLog';
// import PlanningTool from './PlanningTool';
// import ProductionPlan from './ProductionPlan';

class AppRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/" component={ProfileList}/>
        <Route exact path="/prism/profiles" component={ProfileList}/>
        <Route exact path="/prism/profiles/:hashId" component={ProfileDetail}/>
        <Route exact path="/prism/profiles/reviews/:hashId" component={ProfileReviews}/>
        <Route exact path="/prism/profiles/settings/:hashId" component={ProfileSettings}/>
        <Route exact path="/sa" component={SuperAdmin}/>
        <Route exact path="/qr" exact component={QRReviews}/>
        <Route exact path="/admin" component={Admin2}/>
        <Route exact path="/logout" component={Logout}/>
      </React.Fragment>
    )
  }
}

export default AppRoutes;

// <Route exact path="/attendance-log" component={AttendanceLog}/>
// <Route exact path="/planning-tool" component={PlanningTool}/>
// <Route exact path="/production-plan" component={ProductionPlan}/>
// <Route exact path="/donor-human-stool" component={SubstanceLots}/>
// <Route exact path="/batches" component={Batches}/>

// <Route exact path="/locations" component={Locations}/>
// <Route exact path="/location-waitlist/:locationHashId" component={LocationWaitlist}/>
// <Route exact path="/location-reservations/:locationHashId" component={Reservations}/>
// <Route exact path="/users" component={Users}/>
// <Route exact path="/incidents" component={Locations}/>
// <Route exact path="/reporting" component={Checkins}/>
// <Route exact path="/reporting-feedback/:questionnaire_hash_id" component={FeedbackReports}/>
// <Route exact path="/feedback/:business_hash_id" component={Questionnaires}/>
// <Route exact path="/incentives/:business_hash_id" component={Incentives}/>
// <Route exact path="/ci/:shortCode" render={(props) => <Waitlist {...props} currentState={1}/>}/>
// <Route exact path="/wait/:locationCode" render={(props) => <Waitlist {...props} currentState={1}/>}/>
// <Route exact path="/konva" component={KonvaTest}/>
// <Route exact path="/konva2" component={KonvaTest2}/>
// <Route exact path="/location-settings/:locationHashId" component={LocationSettings}/>
