import React, {Component} from 'react';

class ProfileInfo extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render(){
    const {item, handleInputChange, submitHandler, aspect_custom_list, sys_schedule_list} = this.props;
    const isInvalid = item.name === '';
    return (
      <React.Fragment>
            <h3 className="m-t-0"><i className="pg-icon">alert</i> Information</h3>
            <div className="">
              <form role="form" id="form-register">
                <div className="form-group-attached">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group form-group-default">
                        <label>Profile Name</label>
                        <input type="text" name="name" placeholder="" className="form-control" onChange={handleInputChange} value={item.name} required/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group form-group-default">
                        <label>Description</label>
                        <textarea className="form-control" name="description" value={item.description} onChange={handleInputChange}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group form-group-default">
                        <label>Aspect Dictionary</label>
                        <select className="form-control" name="aspect_custom_id" onChange={handleInputChange} value={item.aspect_custom_id}>
                          <option value="0">Select a Dictionary</option>
                        {aspect_custom_list ? aspect_custom_list.map((aspect, index)=>
                          <option key={index} value={aspect.id}>{aspect.name}</option>
                        ) : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group form-group-default">
                        <label>Automated Analysis Schedule</label>
                        <select className="form-control" name="sys_schedule_id" onChange={handleInputChange} value={item.sys_schedule_id}>
                          <option value="0">Select a Schedule</option>
                        {sys_schedule_list ? sys_schedule_list.map((schedule, sindex)=>
                          <option value={schedule.id} key={sindex}>{schedule.name}</option>
                        ) : null}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row m-t-10">
                <div className="col-6 offset-6">
                  <div className="btn-group pull-right">
                    <button aria-label="" type="submit" className={isInvalid ? "btn btn-primary disabled" : "btn btn-primary"} onClick={isInvalid ? ()=>{return false;} : submitHandler}>Save Profile</button>
                  </div>
                </div>
              </div>
            </div>
    </React.Fragment>
    )
  }
}

export default ProfileInfo;
