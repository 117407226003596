import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { connectionGet } from '../utils/connection';
import { Spinner } from 'reactstrap';

@inject('userStore')
class ReviewSources extends Component {
  constructor(props){
    super(props);
    this.state = {
      source_list:[],
      loading:true
    }
  }

  componentDidMount() {
    this.fetchSources();
  }

  fetchSources = () => {
    this.setState({
      loading:true
    },()=>{
      connectionGet('review-sources', {}, {username: this.props.userStore.token}
      ).then((res) => {
        console.log(res.data);
        this.setState({
          source_list:res.data.source_list,
          loading:false
        });
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  render() {
    const {loading, source_list} = this.state;
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header separator m-b-15">
            <h4 className="card-title">Review Source List</h4>
            <div className="card-controls">
              <ul>
                <li>
                  <div className="dropdown">
                    <a id="card-settings" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                      <i className="card-icon card-icon-settings "></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings">
                      <a href="#" className="dropdown-item">Add New</a>
                    </div>
                  </div>
                </li>
                <li><a href="#" className="card-refresh" data-toggle="refresh" onClick={this.fetchSources}><i className="card-icon card-icon-refresh"></i></a></li>
              </ul>
            </div>

          </div>
          <div className="card-body">
            {loading ?
              <div className="card-body">
                <h4><Spinner color="primary"/> Loading...</h4>
              </div>
            :
            <table className="table table-condensed table-bordered">
              <thead>
                <tr>
                  <th width="30%">Name</th>
                  <th width="20%">Description</th>
                  <th width="20%">Actions</th>
                </tr>
              </thead>
              <tbody>
              {source_list ? source_list.map((source, index) =>
                <tr key={index}>
                  <td>{source.name}</td>
                  <td>{source.description}</td>
                  <td><button className="btn btn-sm btn-default"><i className="pg-icon">pencil</i> Edit </button></td>
                </tr>
              ) : "Loading"}
              </tbody>
            </table>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReviewSources;
