import React, { Component } from 'react';
import Score from '../common/Score';
import ReviewBlock from '../common/ReviewBlock';

class AnalysisRow extends Component {
  constructor(props){
    super(props);
    this.state = {
      sentence_list:[]
    }
  }

  median = (values) => {
    if(values.length === 0) return 0;

    values.sort(function(a,b){
      return a-b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2)
      return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }

  render() {
    const {review, quickviewHandler, editHandler, selectedId, readonly, accountPermission, openReviewQuickviewHandler, openReviewAdjustQuickviewHandler, removeReview} = this.props;
    let grade = 'C';
    let median = 0;
    let analyzed = false;
    if (review.analyzed && review.analyzed.analysis.length > 0) {
      if (Array.isArray(review.analyzed.analysis)){
        let value_list = [];
        review.analyzed.analysis.map((sentence) => {
          if (Math.abs(sentence.sentiment[0]) > .1){
            value_list.push(sentence.sentiment[0])
          }
        });
        median = this.median(value_list);
        median = parseInt((median + 1) * 50);
        analyzed = true;
      } else {
        median = review.sentiment_median;
      }
    } else {
      median = review.sentiment_median;
    }
    switch (true) {
      case median < 50:
        grade = 'F';
        break;
      case median < 60:
        grade = 'D';
        break;
      case median < 70:
        grade = 'C';
        break;
      case median < 80:
        grade = 'B';
        break;
      case median < 90:
        grade = 'A';
        break;
      case median <= 100:
        grade = 'A+';
        break;
      default:
    }

    return (
      <div className={selectedId && review.id === selectedId ? "card card-default m-b-0" : "card card-default m-b-0"}>
        <div className="card-header separator m-b-15">
          <div className="card-title">{review.location_name}</div>
          <div className="card-controls">
            <ul>
              <li>
                <div className="dropdown" style={{zIndex:999}}>
                  <a id="card-settings" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button">
                    <i className="card-icon card-icon-settings"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings">
                    {analyzed ? <a href="#" onClick={()=>openReviewQuickviewHandler(review)} className="dropdown-item">Detail</a> : null}
                    { review.sys_review_source !== 'RE-VU.me' ? <a href={review.url} target="_blank" className="dropdown-item">View Original</a> : null}
                    {analyzed ? <a href="#" onClick={()=>openReviewAdjustQuickviewHandler(review)}className="dropdown-item">Adjust Score</a> : null}
                    {analyzed ? <a href="#" onClick={()=>removeReview(review)}className="dropdown-item">Remove Review</a> : null}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="card-body p-b-0">
          <div className="row">
            <div className="col-2">
              <Score asLetter={false} score={review.sentiment_median} size={50} isConverted={true} scoreType={'test'} count={1} insights={1}/>
              <div className="profile-img-wrapper m-t-5 inline">
                <img width="35" height="35" data-src-retina={require('../img/' + review.sys_review_source_img)} data-src={require('../img/' + review.sys_review_source_img)} alt={review.sys_review_source} src={require('../img/' + review.sys_review_source_img)}/>
              </div>
              <br/>
              <div className="inline">
                <p className="small hint-text m-t-5">
                {Array(review.rating_value).fill().map((_, i) =>
                  <small key={i} className="fs-30" style={{color:'#FFd945'}}><i className="pg-icon">star</i></small>
                )}
                </p>
              </div>
            </div>
            <div className="col-10">
              <ReviewBlock review={review}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AnalysisRow;

// <div className="media flex-column b-1 p-0 mx-auto">
//   <span className="m-auto p-2">
//     <span className="fs-50" style={{fontFamily:'Aleo, serif'}}>{grade}</span>
//   </span>
//   <div className="flexbox bg-pale-secondary bt-1 border-light px-12 py-10 w-100">
//     <span className="flex-grow">{median}</span>
//     <a className="media-action hover-primary" href="#" onClick={editHandler} data-provide="tooltip" title="" data-original-title="Edit"><i className="ti-pencil"></i></a>
//     <a className="media-action hover-danger" href="#" data-provide="tooltip" title="" data-original-title="Delete"><i className="ti-close"></i></a>
//   </div>
// </div>


// <div className={selectedId && review.id === selectedId ? "bg-pale-yellow card card-bordered cursor-pointer mb-20" : "card card-bordered cursor-pointer mb-20"}>
//   <div className="media bb-1 border-fade">
//     <div className="media-body">
//       <img className="avatar avatar-sm pull-left mr-20" src={require('../img/' + review.sys_review_source_img)} alt="source"/>
//       <div className="pull-left">
//       {Array(review.rating_value).fill().map((_, i) =>
//         <small key={i} className="text-warning fs-30"><i className="fa fa-star pr-1"></i></small>
//       )}
//       </div>
//     </div>
//     <div className="pull-left">
//       <time className="mr-20">{review.review_date}</time>
//       <div className="pull-right btn-group">
//         <button className="btn btn-xs btn-w-xs btn-outline btn-primary" onClick={()=>quickviewHandler(review)}>Detail</button>
//         {!readonly ?
//         <a className="btn btn-xs btn-outline btn-primary" href={review.url} target="_blank">View Original</a>
//         : null}
//         {accountPermission && accountPermission.sys_role_id === 1 ?
//         <button className="btn btn-xs btn-outline btn-primary" onClick={editHandler}>Adjust Score</button>
//         : null}
//       </div>
//     </div>
//   </div>
//   <div className="card-body row">
//     <div className="col-12 mb-10">
//       <h6>{review.location_name}</h6>
//     </div>
//     <div className="col-sm-2">
//       <Score asLetter={false} score={review.sentiment_median} size={50} isConverted={true} scoreType={'test'} count={1}/>
//     </div>
//     <div className="col-sm-10">
//       <ReviewBlock review={review}/>
//     </div>
//   </div>
// </div>
