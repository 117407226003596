import React, { Component } from 'react';
import {inject} from 'mobx-react';
import { connectionGet, connectionPost } from '../utils/connection';
import DatePicker from 'react-datepicker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Modal from 'react-bootstrap4-modal';
import {Spinner} from 'reactstrap';
import AnalysisRow from './AnalysisRow';
import AnalysisRowQuickview from './AnalysisRowQuickview';
import ProfileReviewsAdjustQuickview from './ProfileReviewsAdjustQuickview';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class Filter{
  location_list = [];
  date_range = [1, 'Today'];
  start_date = new Date();
  end_date = new Date();
  aspect_custom_id = 0;
}

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class ProfileReviews extends Component {
  constructor(props){
    super(props);
    this.state = {
      profile:null,
      entity_list:[],
      thing_list:[],
      selected_date:['week', 'Last Week'],
      location_dict:{},
      filter:new Filter(),
      date_list:[[1,'Today'], [7,'Last Week'],[30,'Last 30 Days'],[90,'Last 90 Days'],[365,'Last Year'], [0,'Custom Range']],
      review_list:[],
      original_review_list:null,
      quickviewVisible:false,
      adjustQuickviewVisible:false,
      review:null,
      visible:false,
      height:0,
      start_date: new Date(),
      end_date: new Date(),
      loading:false,
      accountPermission:null,
      filter_score:0,
      filter_grade:0,
      editReview:null,
      accountList:[]
    }
  }

  componentDidMount(){
    this.fetchProfile();
  }

  setStart = (date) => {
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
    updated_filter.start_date = moment(date).format('MM/DD/YYYY');
    updated_filter.date_range = [0, 'Custom Range'];
    this.setState({
      selected_date: updated_filter.date_range,
      start_date: date,
      filter:updated_filter
    });
  }

  setEnd = (date) => {
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
    updated_filter.end_date = moment(date).format('MM/DD/YYYY');
    updated_filter.date_range = [0, 'Custom Range'];
    this.setState({
      selected_date: updated_filter.date_range,
      end_date: date,
      filter:updated_filter
    });
  }

  setDateRange = (e) => {
    let range = this.state.date_list.find(x => x[0] == parseInt(e.target.value));
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.date_range = range;
    if (range[0] === 0){
      // set the start and end
      updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
      updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
    }
    console.log(updated_filter.date_range);
    this.setState({
      selected_date:range,
      filter:updated_filter
    })
  }

  handleInputChange =(e) => {
    let updated_filter = Object.assign({}, this.state.filter);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.name === 'location_list'){
      var location_value = parseInt(e.target.value);
      if (e.target.checked && !updated_filter.location_list.includes(location_value)){
        updated_filter.location_list.push(location_value)
      } else {
        var index = updated_filter.location_list.indexOf(location_value);
        if (index !== -1) updated_filter.location_list.splice(index, 1);
      }
    }
    this.setState({filter:updated_filter},()=>console.log(this.state.filter));
  }

  selectAll = () => {
    let updated_filter = Object.assign({}, this.state.filter);
    Object.keys(this.state.profile.thing_dict).map((key, index)=>{
      if (!updated_filter.location_list.includes(parseInt(key))){
        updated_filter.location_list.push(parseInt(key));
      }
    })
    this.setState({filter:updated_filter});
  }

  deselectAll = () => {
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.location_list = [];
    this.setState({filter:updated_filter});
  }

  filterGrade = (e) => {
    let value = parseInt(e.target.value);
    if (this.state.filter_score !== 0){
      var tmp_review_list = this.state.original_review_list.filter((review) =>
        review.sentiment_median <= value && review.rating_value === this.state.filter_score
      );
    } else {
      var tmp_review_list = this.state.original_review_list.filter((review) =>
        review.sentiment_median <= value
      );
    }
    this.setState({
      filter_grade:value,
      review_list:tmp_review_list
    });
  }

  filterScore = (e) => {
    let value = parseInt(e.target.value);
    if (this.state.filter_grade !== 0){
      var tmp_review_list = this.state.original_review_list.filter((review) =>
        review.rating_value === value && review.sentiment_median <= this.state.filter_grade
      );
    } else {
      var tmp_review_list = this.state.original_review_list.filter((review) =>
        review.rating_value === value
      );
    }
    this.setState({
      filter_score:value,
      review_list:tmp_review_list
    });
  }

  clearFilters = () => {
    this.setState({
      review_list:this.state.original_review_list,
      filter_score:0,
      filter_grade:0
    })
  }

  getResults = (e) => {
    e.preventDefault();
    this.fetchResults();
  }

  fetchResults = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionPost('profiles/reviews', {filter:this.state.filter}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          this.setState({
            review_list:res.data.results,
            original_review_list:res.data.results,
            loading:false
          })
        }
      }).catch((error) => {
        this.setState({
          loading:false
        })
        return error;
      });
    });
  }

  fetchProfile = () => {
    connectionGet('v2/profiles/' + this.props.match.params.hashId, {}, { username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        let updated_filter = Object.assign({}, this.state.filter);
        updated_filter.aspect_custom_id = res.data.profile.aspect_custom_id;
        this.setState({
          profile:res.data.profile,
          filter:updated_filter,
          accountList:res.data.profile.account_list
        },()=>this.selectAll());
      }
    }).catch((error) => {
      return error;
    });
  }

  openReviewQuickview = (review) => {
    console.log(review);
    if (Array.isArray(review.analyzed.analysis)){
      if (review.analyzed){
        if (this.state.quickviewVisible && this.state.review !== review){
          this.setState({
            review:review
          })
        } else {
          this.setState({
            quickviewVisible:!this.state.quickviewVisible,
            review:review
          })
        }
      }
    } else {
      alert('not analyzed yet');
    }
  }

  openReviewAdjustQuickview = (review) => {
    if (Array.isArray(review.analyzed.analysis)){
      if (review.analyzed){
        if (this.state.adjustQuickviewVisible && this.state.review !== review){
          this.setState({
            review:review
          })
        } else {
          this.setState({
            adjustQuickviewVisible:!this.state.adjustQuickviewVisible,
            review:review
          })
        }
      }
    }
  }

  removeReview = (review) => {
    connectionPost('remove-review', {thing_review_id:review.thing_review_id}, {username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        alert(res.data.message);
        this.fetchResults();
      } else {
        alert(res.data.message);
      }
    }).catch((error) => {
      return error;
    });
  }

  emailReview = (review, email) => {
    connectionPost('email-reviews', {thing_review_analysis_id:review.id, email:email}, {username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    }).catch((error) => {
      return error;
    });
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:false,
      review:null
    })
  }

  adjustCloseHandler = (editReview) => {
    if (editReview !== null){
      // try to set the selected review
      let tmp_review_list = Object.assign([], this.state.review_list);
      let tmp_original_review_list = Object.assign([], this.state.original_review_list);
      let index = tmp_review_list.findIndex(x => x.id === editReview.id);
      if (index > 0){
        tmp_review_list[index] = editReview;
      }
      index = tmp_original_review_list.findIndex(x => x.id === editReview.id);
      if (index > 0){
        tmp_original_review_list[index] = editReview;
      }
      this.setState({
        adjustQuickviewVisible:false,
        review:null,
        review_list:tmp_review_list,
        original_review_list:tmp_original_review_list
      },()=>toast.success('Adjusted Scoring', toastParams))
    }
  }

  render(){
    if (!this.state.profile) return <div className="no-padding container-fixed-lg bg-white"><div className="container"><div className="card card-transparent"><h3><Spinner/> Loading...</h3></div></div></div>;
    const { profile, quickviewVisible, loading, date_list, filter, start_date, end_date, review_list, accountPermission, review, accountList, adjustQuickviewVisible, filter_score, filter_grade} = this.state;
    const readonly = accountPermission && accountPermission.sys_role_id !== 2 ? false : true;
    return (
      <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
      <Modal visible={quickviewVisible} className="modal slide-right" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.closeHandler}>
        {this.state.review ?
          <AnalysisRowQuickview review={this.state.review} closeHandler={this.closeHandler} readonly={readonly} emailReview={this.emailReview} accountList={accountList}/>
        : <div></div>
        }
      </Modal>
      <Modal visible={adjustQuickviewVisible} className="modal slide-right" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.closeHandler}>
        {this.state.review ?
          <ProfileReviewsAdjustQuickview review={this.state.review} closeHandler={this.adjustCloseHandler} readonly={readonly}/>
        : <div></div>
        }
      </Modal>
      <div className="no-padding container-fixed-lg bg-white" style={{minHeight:"600px"}}>
        <div className="container">
          <div className="card card-transparent">
            <div className="card-header p-l-0">
              <div className="card-title">
                <h4>{profile ? profile.name : null} Reviews</h4>
              </div>
            </div>
            <div className="card-body p-l-0 p-r-0">
              <p className="text-hint">Filter Review Results Below</p>
              <div className="row">
                <div className="col-lg-3 col-sm-12 b-r b-grey">
                  <form role="form">
                    <div className="">
                      <div className="row">
                        <h6>Date Filters</h6>
                        <div className="form-group form-group-default">
                          <label>Date Range:</label>
                          <select className="form-control" onChange={this.setDateRange}>
                          {date_list.map((range, index) =>
                            <option key={index} value={range[0]}>{range[1]}</option>
                          )}
                          </select>
                        </div>
                      </div>
                      {filter.date_range[0] === 0 ?
                        <React.Fragment>
                        <div className="row">
                          <div className="form-group form-group-default" style={{overflow:'visible'}}>
                            <label>Date Start:</label>
                            <DatePicker
                              className="form-control"
                              selected={start_date}
                              onChange={this.setStart}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group form-group-default" style={{overflow:'visible'}}>
                            <label>Date End:</label>
                            <DatePicker
                              className="form-control"
                              selected={end_date}
                              onChange={this.setEnd}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>
                        </div>
                        </React.Fragment>
                      : null}
                      <div className="row">
                        <h6>Score Filters</h6>
                        <div className="form-group form-group-default">
                          <label>Filter Score</label>
                          <select className="form-control" onChange={this.filterScore} value={filter_score}>
                            <option value={0}>Select a Rating</option>
                            <option value={1}>1 Star</option>
                            <option value={2}>2 Star</option>
                            <option value={3}>3 Star</option>
                            <option value={4}>4 Star</option>
                            <option value={5}>5 Star</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group form-group-default">
                          <label>Filter Grade</label>
                          <select className="form-control" onChange={this.filterGrade} value={filter_grade}>
                            <option value={0}>Select a Grade</option>
                            <option value={50}>{"<50"}</option>
                            <option value={60}>{"<60"}</option>
                            <option value={70}>{"<70"}</option>
                            <option value={80}>{"<80"}</option>
                            <option value={90}>{"<90"}</option>
                          </select>
                        </div>
                      </div>
                      <div className="row m-t-10">
                        <div className="col-12">
                          <div className="btn-group pull-right">
                            <button className="btn btn-sm btn-default" onClick={this.clearFilters}>Clear Score Filters</button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group">
                          <h6>Location Filters</h6>
                          {Object.keys(profile.thing_dict).map((key, thing_index)=>{
                            return(
                            <div className="form-check" key={thing_index}>
                              <input type="checkbox" id={key} value={key} name="location_list" onChange={this.handleInputChange} checked={filter.location_list.includes(parseInt(key))}/>
                              <label htmlFor={key}>
                                {profile.thing_dict[key]}
                              </label>
                            </div>
                          )
                          })}
                        </div>
                      </div>
                      <div className="row m-t-10">
                        <div className="col-12">
                          <div className="btn-group pull-right">
                            <button className="btn btn-sm btn-success" onClick={this.selectAll}>Select All</button>
                            <button className="btn btn-sm btn-warning" onClick={this.deselectAll}>Deselect All</button>
                          </div>
                        </div>
                      </div>
                      <div className="row b-t b-grey p-t-10 m-t-10">
                        <div className="col-12">
                          <div className="btn-group pull-right">
                            <button className="btn btn-primary btn-lg" type="button" onClick={this.fetchResults}>{loading ? 'Fetching Please Wait' : 'Fetch Results' }</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-9 col-sm-12" style={{height:'600px'}}>
                  <PerfectScrollbar>
                {loading ? <h4><Spinner color="primary"/> Loading...</h4> : null}
                {review_list.length > 0 ?
                  review_list.map((review, index) =>
                    <AnalysisRow key={review} review={review} selectedId={this.state.review ? this.state.review.id : 0} readonly={readonly} accountPermission={accountPermission} openReviewQuickviewHandler={this.openReviewQuickview} openReviewAdjustQuickviewHandler={this.openReviewAdjustQuickview} removeReview={this.removeReview}/>
                  )
                : <span className="text-info">No Results for this Date Range</span>}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default ProfileReviews;
