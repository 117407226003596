import React, { Component } from 'react';
import {inject} from 'mobx-react';
import { connectionGet, connectionPost } from '../utils/connection';
import DatePicker from 'react-datepicker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {CSVLink, CSVDownload} from 'react-csv';
import Modal from 'react-bootstrap4-modal';
import QuestionResults from './QuestionResults';
import {Spinner} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class Filter{
  location_list = [];
  date_range = [1, 'Today'];
  start_date = new Date();
  end_date = new Date();
}

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class QRReviews extends Component {
  constructor(props){
    super(props);
    this.state = {
      thing_list:[],
      csv_list:[],
      selected_date:['week', 'Last Week'],
      location_dict:{},
      filter:new Filter(),
      date_list:[[1,'Today'], [7,'Last Week'],[30,'Last 30 Days'],[90,'Last 90 Days'],[365,'Last Year'], [0,'Custom Range']],
      review_list:[],
      original_review_list:null,
      review:null,
      visible:false,
      height:0,
      start_date: new Date(),
      end_date: new Date(),
      loading:false,
      accountPermission:null
    }
  }

  componentDidMount(){
    this.fetchThings();
  }

  fetchThings = () => {
    this.setState({
      loading:true
    },()=>{
      connectionGet('things-small', {}, {username: this.props.userStore.token}
      ).then((res) => {
        this.setState({
          thing_list:res.data.thing_list,
          loading:false
        });
      }).catch((error) => {
        console.log(error);
        return error;
      });
    })
  }

  setStart = (date) => {
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
    updated_filter.start_date = moment(date).format('MM/DD/YYYY');
    updated_filter.date_range = [0, 'Custom Range'];
    this.setState({
      selected_date: updated_filter.date_range,
      start_date: date,
      filter:updated_filter
    });
  }

  setEnd = (date) => {
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
    updated_filter.end_date = moment(date).format('MM/DD/YYYY');
    updated_filter.date_range = [0, 'Custom Range'];
    this.setState({
      selected_date: updated_filter.date_range,
      end_date: date,
      filter:updated_filter
    });
  }

  setDateRange = (e) => {
    let range = this.state.date_list.find(x => x[0] == parseInt(e.target.value));
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.date_range = range;
    if (range[0] === 0){
      // set the start and end
      updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
      updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
    }
    this.setState({
      selected_date:range,
      filter:updated_filter
    })
  }

  handleInputChange =(e) => {
    let updated_filter = Object.assign({}, this.state.filter);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.name === 'location_list'){
      var location_value = parseInt(e.target.value);
      if (e.target.checked && !updated_filter.location_list.includes(location_value)){
        updated_filter.location_list.push(location_value)
      } else {
        var index = updated_filter.location_list.indexOf(location_value);
        if (index !== -1) updated_filter.location_list.splice(index, 1);
      }
    }
    this.setState({filter:updated_filter});
  }

  selectAll = () => {
    let updated_filter = Object.assign({}, this.state.filter);
    this.state.thing_list.map((thing, index)=>{
      if (!updated_filter.location_list.includes(parseInt(thing.thing_id))){
        updated_filter.location_list.push(parseInt(thing.thing_id));
      }
    })
    this.setState({filter:updated_filter});
  }

  deselectAll = () => {
    let updated_filter = Object.assign({}, this.state.filter);
    updated_filter.location_list = [];
    this.setState({filter:updated_filter});
  }

  getResults = (e) => {
    e.preventDefault();
    this.fetchResults();
  }

  fetchResults = (e) => {
    e.preventDefault();
    this.setState({
      loading:true
    }, ()=>{
      connectionPost('thing/reviews', {filter:this.state.filter}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          this.setState({
            review_list:res.data.results,
            csv_list:res.data.csv_list,
            original_review_list:res.data.results,
            loading:false
          })
        }
      }).catch((error) => {
        this.setState({
          loading:false
        })
        return error;
      });
    });
  }


  emailReview = (review, email) => {
    connectionPost('email-reviews', {thing_review_analysis_id:review.id, email:email}, {username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    }).catch((error) => {
      return error;
    });
  }

  render(){
    if (this.state.loading) return <div className="no-padding container-fixed-lg bg-white"><div className="container"><div className="card card-transparent"><h3><Spinner/> Loading...</h3></div></div></div>;
    const { loading, date_list, filter, start_date, end_date, review_list, review, thing_list, csv_list} = this.state;
    return (
      <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="no-padding container-fixed-lg bg-white" style={{minHeight:"600px"}}>
        <div className="container">
          <div className="card card-transparent">
            <div className="card-header p-l-0">
              <div className="card-title">
                <h4>QR Reviews</h4>
              </div>
            </div>
            <div className="card-body p-l-0 p-r-0">
              <p className="text-hint">Filter Review Results Below</p>
              <div className="row">
                <div className="col-lg-3 col-sm-12 b-r b-grey">
                  <form role="form">
                    <div className="">
                      <div className="row">
                        <h6>Date Filters</h6>
                        <div className="form-group form-group-default">
                          <label>Date Range:</label>
                          <select className="form-control" onChange={this.setDateRange}>
                          {date_list.map((range, index) =>
                            <option key={index} value={range[0]}>{range[1]}</option>
                          )}
                          </select>
                        </div>
                      </div>
                      {filter.date_range[0] === 0 ?
                        <React.Fragment>
                        <div className="row">
                          <div className="form-group form-group-default" style={{overflow:'visible'}}>
                            <label>Date Start:</label>
                            <DatePicker
                              className="form-control"
                              selected={start_date}
                              onChange={this.setStart}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group form-group-default" style={{overflow:'visible'}}>
                            <label>Date End:</label>
                            <DatePicker
                              className="form-control"
                              selected={end_date}
                              onChange={this.setEnd}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>
                        </div>
                        </React.Fragment>
                      : null}
                      <div className="row">
                        <div className="form-group">
                          <h6>Survey Filters</h6>
                          {thing_list.map((thing, thing_index)=>{
                            return(
                            <div className="form-check" key={thing_index}>
                              <input type="checkbox" id={thing.thing_id} value={thing.thing_id} name="location_list" onChange={this.handleInputChange} checked={filter.location_list.includes(parseInt(thing.thing_id))}/>
                              <label htmlFor={thing.thing_id}>
                                {thing.name}
                              </label>
                            </div>
                          )
                          })}
                        </div>
                      </div>
                      <div className="row m-t-10">
                        <div className="col-12">
                          <div className="btn-group pull-right">
                            <button className="btn btn-sm btn-success" type="button" onClick={this.selectAll}>Select All</button>
                            <button className="btn btn-sm btn-warning" type="button" onClick={this.deselectAll}>Deselect All</button>
                          </div>
                        </div>
                      </div>
                      <div className="row b-t b-grey p-t-10 m-t-10">
                        <div className="col-12">
                          <div className="btn-group pull-right">
                            <button className="btn btn-primary btn-lg" type="button" onClick={this.fetchResults}>{loading ? 'Fetching Please Wait' : 'Fetch Results' }</button>
                          </div>
                        </div>
                      </div>
                      {csv_list.length > 0 ?
                        <div className="row b-t b-grey p-t-10 m-t-40">
                          <div className="col-12">
                            <div className="btn-group pull-left">
                              <CSVLink data={csv_list} className="btn btn-complete btn-lg" filename={"QRReview_export.csv"}>Download these Reviews</CSVLink>
                            </div>
                          </div>
                        </div>
                      :null }
                    </div>
                  </form>
                </div>
                <div className="col-lg-9 col-sm-12">
                <PerfectScrollbar>
              {loading ? <h4><Spinner color="primary"/> Loading...</h4> : null}
              {review_list.length > 0 ?
                review_list.map((review_obj, review_index)=>{
                  let review = Object.values(review_obj)[0];
                  return(
                    <div className="card b-grey b-a m-b-10 p-b-10" key={review_index}>
                      <div className="card-header separator m-b-15">
                        <div className="card-title">{review[0].name} <small className="text-complete">left on {review[0].review_date}</small></div>
                      </div>
                      <div className="card-body p-b-0">
                        {review.map((q, q_index) => {
                          return (<QuestionResults key={q_index} result={q}/>);
                        })}
                      </div>
                    </div>
                  );
                })
              : <span className="text-info">No Results for this Date Range</span>}
                </PerfectScrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default QRReviews;
