import React, { Component } from 'react';
import {Spinner} from 'reactstrap';
import HBarChart from '../charts/HBarChart';
import Score from '../common/Score';

class AnalysisRowQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      comment:'',
      email:''
    }
  }

  uniqueId = () => {
    return new Date().getTime();
  }

  handleChange = (e) => {
    if (e.target.name === 'email'){
      this.setState({
        email:e.target.value
      })
    }
    if (e.target.name === 'comment'){
      this.setState({
        comment:e.target.value
      })
    }
  }

  sendEmail = (review) => {
    if (this.state.email !== ''){
      this.props.emailReview(review, this.state.email);
    } else {
      alert('Please select a recipient');
    }
  }

  renderHtml = (content) => {
    return {__html: content};
  }


  render(){
    if (!this.props.review) return <div></div>;
    const {closeHandler, review, readonly, emailReview, accountList} = this.props;
    if (!review) {
      return <React.Fragment>No Review</React.Fragment>;
    }
    let data = {
      labels:review.aspect_list,
      datasets:[
        {
          label:'Aspects',
          data:Object.values(review.aspect_score).map((score)=>(score + 1) * 50)
        }
      ]
    }

    // const {qvTitle, loading, review_list, closeHandler} = this.props;
    return (
      <React.Fragment>
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header clearfix text-left">
            <button aria-label="" type="button" className="close" onClick={closeHandler} aria-hidden="true"><i className="pg-icon">close</i>
            </button>
            <h5>Review Detail</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 m-b-30">
                <Score asLetter={false} score={review.sentiment_median} size={50} isConverted={true} scoreType={'test'} count={1} insights={1}/>
                <div className="profile-img-wrapper m-t-5 inline">
                  <img width="35" height="35" data-src-retina={require('../img/' + review.sys_review_source_img)} data-src={require('../img/' + review.sys_review_source_img)} alt={review.sys_review_source} src={require('../img/' + review.sys_review_source_img)}/>
                </div>
                <br/>
                <div className="pull-left">
                {Array(review.rating_value).fill().map((_, i) =>
                  <small key={i} className="fs-30" style={{color:'#FFd945'}}><i className="pg-icon">star</i></small>
                )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <dl className="row">
                  <dt className="col-sm-5" style={{height:'20px'}}>Location</dt>
                  <dd className="col-sm-7 m-b-0" style={{height:'20px'}}>{review.location_name}</dd>
                  <dt className="col-sm-5" style={{height:'20px'}}>Date</dt>
                  <dd className="col-sm-7 m-b-0" style={{height:'20px'}}>{review.review_date}</dd>
                  <dt className="col-sm-5" style={{height:'20px'}}>Reviewed By</dt>
                  <dd className="col-sm-7 m-b-0" style={{height:'20px'}}>{review.name}</dd>
                  {!readonly ?
                  <React.Fragment>
                  <dt className="col-sm-5" style={{height:'20px'}}>Reference</dt>
                  <dd className="col-sm-7 m-b-0" style={{height:'20px'}}><a href={review.url} target="_blank"><i className="fa fa-external-link"></i></a></dd>
                  </React.Fragment>
                  : null}
                </dl>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
              { review.sys_review_source === 'RE-VU.me' ?
                null
              :
                <p className="text-hint" dangerouslySetInnerHTML={this.renderHtml(review.review_text_raw)}/>
              }
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <HBarChart data={data}/>
              </div>
            </div>
            <div className="row m-t-20">
              <div className="form-group col-12 input-group">
                <select className="form-control" name="email" onChange={this.handleChange}>
                  <option>Email review</option>
                {accountList.map((account, account_index)=>
                  <option key={account_index} value={account.email}>{account.email}</option>
                )}
                </select>
                <div className="input-group-append">
                  <button className="input-group-text primary" onClick={()=>this.sendEmail(review)}><i className="pg-icon">mail</i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default AnalysisRowQuickview;
