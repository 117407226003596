import firebase from 'firebase/app';
import { auth } from './firebase';

// create user
export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

export const doSendPasswordResetEmail = (email) =>
  auth.sendPasswordResetEmail(email);

// Sign out
export const doSignOut = () =>
    auth.signOut();

// update password
export const doPasswordUpdate = (password) =>
    auth.currentUser.updatePassword(password);

// update email
export const doEmailUpdate = (email) =>
    auth.currentUser.updateEmail(email);

// get new credentials
export const reauthenticate = (password) => {
      var user = auth.currentUser;
      var cred = firebase.auth.EmailAuthProvider.credential(
          user.email, password);
      return user.reauthenticateWithCredential(cred);
    }
